import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';

import {CoreCommonModule} from '@core/common.module';
import {CoreMenuModule} from '@core/components';

import {VerticalMenuComponent} from 'app/layout/components/menu/vertical-menu/vertical-menu.component';
import {LucideAngularModule, icons} from 'lucide-angular';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelPropagation: false
};
@NgModule({
    declarations: [VerticalMenuComponent],
    imports: [CoreMenuModule, CoreCommonModule, PerfectScrollbarModule, RouterModule, LucideAngularModule.pick(icons)],
    providers: [
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        }
    ],
    exports: [VerticalMenuComponent]
})
export class VerticalMenuModule {}

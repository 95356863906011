import {Component, Optional, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UploadPublicService} from 'api/store_public_v1';
import {ImageCropperComponent} from 'app/components-ui/image-cropper/component/image-cropper.component';
import {ImageCroppedEvent} from 'app/components-ui/image-cropper/interfaces';
import {MyBaseComponent} from 'app/core/my-base.component';
import {dataURItoBlob} from 'app/helpers/FileManagers';

@Component({
    selector: 'app-dialog-cropper',
    templateUrl: './dialog-cropper.component.html',
    styleUrls: ['./dialog-cropper.component.scss']
})
export class DialogCropperComponent extends MyBaseComponent {
    @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;
    imageFileChanged: File;
    aspectRatio: number = 1;
    croppedImage: any = '';
    showCropper = false;
    constructor(
        @Optional() private readonly activeModal: NgbActiveModal,
        private uploadPublicService: UploadPublicService
    ) {
        super();
    }
    dismiss(): void {
        if (this.activeModal) this.activeModal.close(false);
    }
    uploadImage(): void {
        this.initLoadingStates('uploadImage');
        const imageName = 'name.png';
        const imageBlob = dataURItoBlob(this.croppedImage);
        const imageFile = new File([imageBlob], imageName, {type: 'image/png'});
        this.uploadPublicService.apiPublicUploadImagePostForm(imageFile).subscribe(response => {
            this.activeModal.close(response.url);
            this.successLoadingStates('uploadImage');
        });
    }
    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
    }
    imageLoaded() {
        this.showCropper = true;
        console.log('Image loaded');
    }
    cropperReady() {
        console.log('Cropper ready');
    }
    loadImageFailed() {
        console.log('Load failed');
    }
    rotateLeft() {
        this.imageCropper.rotateLeft();
    }
    rotateRight() {
        this.imageCropper.rotateRight();
    }
    flipHorizontal() {
        this.imageCropper.flipHorizontal();
    }
    flipVertical() {
        this.imageCropper.flipVertical();
    }
    ngOnInit(): void {}
}

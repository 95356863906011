/**
 * public-v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CommonStatusResponse } from '../model/commonStatusResponse';
import { CourseStudentInfoPublicRequest } from '../model/courseStudentInfoPublicRequest';
import { CourseStudentInfoPublicResponseCommonGetLstResponse } from '../model/courseStudentInfoPublicResponseCommonGetLstResponse';
import { CourseStudentLearningPublicRequest } from '../model/courseStudentLearningPublicRequest';
import { CourseStudentLearningResponse } from '../model/courseStudentLearningResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class StudentLearningPublicService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPublicLearningIdGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<CourseStudentLearningResponse>;
    public apiPublicLearningIdGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CourseStudentLearningResponse>>;
    public apiPublicLearningIdGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CourseStudentLearningResponse>>;
    public apiPublicLearningIdGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiPublicLearningIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CourseStudentLearningResponse>('get',`${this.basePath}/api/public/learning/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPublicLearningIdRegisterGet(id: number, observe?: 'body', reportProgress?: boolean): Observable<CommonStatusResponse>;
    public apiPublicLearningIdRegisterGet(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommonStatusResponse>>;
    public apiPublicLearningIdRegisterGet(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommonStatusResponse>>;
    public apiPublicLearningIdRegisterGet(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiPublicLearningIdRegisterGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CommonStatusResponse>('get',`${this.basePath}/api/public/learning/${encodeURIComponent(String(id))}/register`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPublicLearningLogPost(body?: CourseStudentLearningPublicRequest, observe?: 'body', reportProgress?: boolean): Observable<CommonStatusResponse>;
    public apiPublicLearningLogPost(body?: CourseStudentLearningPublicRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommonStatusResponse>>;
    public apiPublicLearningLogPost(body?: CourseStudentLearningPublicRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommonStatusResponse>>;
    public apiPublicLearningLogPost(body?: CourseStudentLearningPublicRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CommonStatusResponse>('post',`${this.basePath}/api/public/learning/log`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPublicLearningQueryPost(body?: CourseStudentInfoPublicRequest, observe?: 'body', reportProgress?: boolean): Observable<CourseStudentInfoPublicResponseCommonGetLstResponse>;
    public apiPublicLearningQueryPost(body?: CourseStudentInfoPublicRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CourseStudentInfoPublicResponseCommonGetLstResponse>>;
    public apiPublicLearningQueryPost(body?: CourseStudentInfoPublicRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CourseStudentInfoPublicResponseCommonGetLstResponse>>;
    public apiPublicLearningQueryPost(body?: CourseStudentInfoPublicRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CourseStudentInfoPublicResponseCommonGetLstResponse>('post',`${this.basePath}/api/public/learning/query`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}

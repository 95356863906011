/**
 * public-v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { StartPartRequest } from '../model/startPartRequest';
import { StudentTakeTestPartResponse } from '../model/studentTakeTestPartResponse';
import { StudentTakeTestPartStatusResponse } from '../model/studentTakeTestPartStatusResponse';
import { StudentTakeTestStatusResponse } from '../model/studentTakeTestStatusResponse';
import { UserExam } from '../model/userExam';
import { UserQuestionLogRequest } from '../model/userQuestionLogRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class TakeTestStudentPublicService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param examId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPublicStudentTakeTestStartExamIdPost(examId: number, observe?: 'body', reportProgress?: boolean): Observable<UserExam>;
    public apiPublicStudentTakeTestStartExamIdPost(examId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserExam>>;
    public apiPublicStudentTakeTestStartExamIdPost(examId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserExam>>;
    public apiPublicStudentTakeTestStartExamIdPost(examId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (examId === null || examId === undefined) {
            throw new Error('Required parameter examId was null or undefined when calling apiPublicStudentTakeTestStartExamIdPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<UserExam>('post',`${this.basePath}/api/public/student/take-test/start/${encodeURIComponent(String(examId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param userExamId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPublicStudentTakeTestUserExamIdGet(userExamId: number, observe?: 'body', reportProgress?: boolean): Observable<StudentTakeTestStatusResponse>;
    public apiPublicStudentTakeTestUserExamIdGet(userExamId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StudentTakeTestStatusResponse>>;
    public apiPublicStudentTakeTestUserExamIdGet(userExamId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StudentTakeTestStatusResponse>>;
    public apiPublicStudentTakeTestUserExamIdGet(userExamId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userExamId === null || userExamId === undefined) {
            throw new Error('Required parameter userExamId was null or undefined when calling apiPublicStudentTakeTestUserExamIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<StudentTakeTestStatusResponse>('get',`${this.basePath}/api/public/student/take-test/${encodeURIComponent(String(userExamId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param userExamId 
     * @param partId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPublicStudentTakeTestUserExamIdPartPartIdGet(userExamId: number, partId: number, observe?: 'body', reportProgress?: boolean): Observable<StudentTakeTestPartStatusResponse>;
    public apiPublicStudentTakeTestUserExamIdPartPartIdGet(userExamId: number, partId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StudentTakeTestPartStatusResponse>>;
    public apiPublicStudentTakeTestUserExamIdPartPartIdGet(userExamId: number, partId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StudentTakeTestPartStatusResponse>>;
    public apiPublicStudentTakeTestUserExamIdPartPartIdGet(userExamId: number, partId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userExamId === null || userExamId === undefined) {
            throw new Error('Required parameter userExamId was null or undefined when calling apiPublicStudentTakeTestUserExamIdPartPartIdGet.');
        }

        if (partId === null || partId === undefined) {
            throw new Error('Required parameter partId was null or undefined when calling apiPublicStudentTakeTestUserExamIdPartPartIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<StudentTakeTestPartStatusResponse>('get',`${this.basePath}/api/public/student/take-test/${encodeURIComponent(String(userExamId))}/part/${encodeURIComponent(String(partId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param userExamId 
     * @param partId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPublicStudentTakeTestUserExamIdPartPartIdPost(userExamId: string, partId: string, body?: StartPartRequest, observe?: 'body', reportProgress?: boolean): Observable<StudentTakeTestPartResponse>;
    public apiPublicStudentTakeTestUserExamIdPartPartIdPost(userExamId: string, partId: string, body?: StartPartRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StudentTakeTestPartResponse>>;
    public apiPublicStudentTakeTestUserExamIdPartPartIdPost(userExamId: string, partId: string, body?: StartPartRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StudentTakeTestPartResponse>>;
    public apiPublicStudentTakeTestUserExamIdPartPartIdPost(userExamId: string, partId: string, body?: StartPartRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userExamId === null || userExamId === undefined) {
            throw new Error('Required parameter userExamId was null or undefined when calling apiPublicStudentTakeTestUserExamIdPartPartIdPost.');
        }

        if (partId === null || partId === undefined) {
            throw new Error('Required parameter partId was null or undefined when calling apiPublicStudentTakeTestUserExamIdPartPartIdPost.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<StudentTakeTestPartResponse>('post',`${this.basePath}/api/public/student/take-test/${encodeURIComponent(String(userExamId))}/part/${encodeURIComponent(String(partId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param userExamId 
     * @param userExamPartId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPublicStudentTakeTestUserExamIdPartUserExamPartIdEndPost(userExamId: number, userExamPartId: number, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiPublicStudentTakeTestUserExamIdPartUserExamPartIdEndPost(userExamId: number, userExamPartId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiPublicStudentTakeTestUserExamIdPartUserExamPartIdEndPost(userExamId: number, userExamPartId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiPublicStudentTakeTestUserExamIdPartUserExamPartIdEndPost(userExamId: number, userExamPartId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userExamId === null || userExamId === undefined) {
            throw new Error('Required parameter userExamId was null or undefined when calling apiPublicStudentTakeTestUserExamIdPartUserExamPartIdEndPost.');
        }

        if (userExamPartId === null || userExamPartId === undefined) {
            throw new Error('Required parameter userExamPartId was null or undefined when calling apiPublicStudentTakeTestUserExamIdPartUserExamPartIdEndPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('post',`${this.basePath}/api/public/student/take-test/${encodeURIComponent(String(userExamId))}/part/${encodeURIComponent(String(userExamPartId))}/end`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param userExamId 
     * @param userExamPartId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPublicStudentTakeTestUserExamIdPartUserExamPartIdQuestionLogPost(userExamId: number, userExamPartId: number, body?: UserQuestionLogRequest, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public apiPublicStudentTakeTestUserExamIdPartUserExamPartIdQuestionLogPost(userExamId: number, userExamPartId: number, body?: UserQuestionLogRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public apiPublicStudentTakeTestUserExamIdPartUserExamPartIdQuestionLogPost(userExamId: number, userExamPartId: number, body?: UserQuestionLogRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public apiPublicStudentTakeTestUserExamIdPartUserExamPartIdQuestionLogPost(userExamId: number, userExamPartId: number, body?: UserQuestionLogRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userExamId === null || userExamId === undefined) {
            throw new Error('Required parameter userExamId was null or undefined when calling apiPublicStudentTakeTestUserExamIdPartUserExamPartIdQuestionLogPost.');
        }

        if (userExamPartId === null || userExamPartId === undefined) {
            throw new Error('Required parameter userExamPartId was null or undefined when calling apiPublicStudentTakeTestUserExamIdPartUserExamPartIdQuestionLogPost.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<boolean>('post',`${this.basePath}/api/public/student/take-test/${encodeURIComponent(String(userExamId))}/part/${encodeURIComponent(String(userExamPartId))}/question-log`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}

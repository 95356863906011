import {Component, EventEmitter, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {Course} from 'api/course_private_v1';

@Component({
    selector: 'app-course-item',
    templateUrl: './course-item.component.html',
    styleUrls: ['./course-item.component.scss']
})
export class CourseItemComponent implements OnInit {
    @Input() course: Course;
    @Input() index: number = 0;
    @Input() type: 'student' | 'manager' = 'manager';
    @Input() menuTemplate!: TemplateRef<any>;
    @Output() redirectToDetail = new EventEmitter();
    thumb: string = '';
    constructor() {}
    stringToSlug(str: string): string {
        const from = 'àáãảạăằắẳẵặâầấẩẫậèéẻẽẹêềếểễệđùúủũụưừứửữựòóỏõọôồốổỗộơờớởỡợìíỉĩịäëïîöüûñçýỳỹỵỷ',
            to = 'aaaaaaaaaaaaaaaaaeeeeeeeeeeeduuuuuuuuuuuoooooooooooooooooiiiiiaeiiouuncyyyyy';
        for (let i = 0, l = from.length; i < l; i++) {
            str = str.replace(RegExp(from[i], 'gi'), to[i]);
        }
        str = str
            .toLowerCase()
            .trim()
            .replace(/[^a-z0-9\-]/g, '-')
            .replace(/-+/g, '-');

        return str;
    }
    ngOnInit(): void {
        this.thumb = this.course.thumb?.length > 0 ? this.course.thumb : `https://placehold.co/230x160?text=${this.stringToSlug(this.course.title)}`;
    }
}

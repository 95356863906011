import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'loading-skeleton',
	templateUrl: './loading-skeleton.component.html',
	styleUrls: ['./loading-skeleton.component.scss']
})
export class LoadingSkeletonComponent implements OnInit {
	@Input() height: string = '';
	@Input() width: string = '';
	@Input() loading: boolean = false;
	constructor() { }

	ngOnInit(): void {
	}

}

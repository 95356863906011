import {Directive, EventEmitter, HostBinding, HostListener, Output} from '@angular/core';

@Directive({
    selector: '[appDragDropFile]',
})
export class DragDropFileDirective {
    @Output() private filesChangeEmitter = new EventEmitter<File[]>();
    @HostBinding('style.background') private background = 'transparent';
    @HostBinding('style.border') private borderStyle = '2px dashed initial';

    @HostListener('dragover', ['$event']) public onDragOver(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();
        this.background = 'transparent';
        this.borderStyle = '2px dashed #334155';
    }
    @HostListener('dragleave', ['$event']) public onDragLeave(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();
        this.background = 'transparent';
        this.borderStyle = '2px dashed #e5e7eb';
    }
    @HostListener('drop', ['$event']) public onDrop(event: any): void {
        event.preventDefault();
        event.stopPropagation();
        this.background = 'transparent';
        this.borderStyle = '2px dashed #e5e7eb';
        let files = event.dataTransfer.files;
        let validFiles: Array<File> = files;
        this.filesChangeEmitter.emit(event);
    }
}

import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {CustomBreakPointsProvider} from 'app/layout/custom-breakpoints';
import {VerticalLayoutModule} from 'app/layout/vertical/vertical-layout.module';
import {LucideAngularModule, icons} from 'lucide-angular';
import {HorizontalStudentModule} from './horizontal-student/horizontal-student.module';
import {HorizontalLayoutModule} from './horizontal/horizontal-layout.module';

@NgModule({
    imports: [FlexLayoutModule.withConfig({disableDefaultBps: true}), VerticalLayoutModule, HorizontalStudentModule, HorizontalLayoutModule, LucideAngularModule.pick(icons)],
    providers: [CustomBreakPointsProvider],
    exports: [VerticalLayoutModule, HorizontalStudentModule, HorizontalLayoutModule],
})
export class LayoutModule {}

import {Component, Optional} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthPublicService, GroupInfoRequest, UserInfoRequest} from 'api/auth_public_v1';
import {SearchAndPaginationOptions} from 'app/core/interfaces/common/SearchAndPaginationOptions';
import {MyBaseComponent} from 'app/core/my-base.component';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {AssignmentDialogResult, GroupInfo, UserInfo} from './interfaces/assignment-dialog.interface';

@Component({
    selector: 'app-assignment-dialog',
    templateUrl: './assignment-dialog.component.html',
    styleUrls: ['./assignment-dialog.component.scss']
})
export class AssignmentDialogComponent extends MyBaseComponent {
    optionsUser = new SearchAndPaginationOptions();
    optionsGroup = new SearchAndPaginationOptions();
    title = '';
    isFullAssignment: boolean = true;
    //
    totalCheckedStudent: number = 0;
    userList: UserInfo[] = [];
    userIdList: number[] = [];
    checkAllUserList: boolean = false;
    //
    totalCheckedGroup: number = 0;
    groupIdList: number[] = [];
    groupList: GroupInfo[] = [];
    checkAllGroupList: boolean = false;

    constructor(
        private authPublicService: AuthPublicService,
        @Optional() private readonly activeModal: NgbActiveModal
    ) {
        super();
    }

    dismiss(): void {
        if (this.activeModal) this.activeModal.close(false);
    }
    checkAllUser(): void {
        this.userList.map(item => (item.checked = this.checkAllUserList));
        this.changeToggleCheckedUser();
    }
    checkAllGroup(): void {
        this.groupList.map(item => (item.checked = this.checkAllGroupList));
        this.changeToggleCheckedGroup();
    }
    assignmentUser(): void {
        const data: AssignmentDialogResult = {
            groupIds: this.groupList.filter(obj => obj.checked === true).map(obj => obj.id),
            studentIds: this.userList.filter(obj => obj.checked === true).map(obj => obj.id)
        };
        if (this.activeModal) this.activeModal.close(data);
    }
    changeToggleCheckedUser(): void {
        this.totalCheckedStudent = this.userList.filter(student => student.checked).length;
    }
    changeToggleCheckedGroup(): void {
        this.totalCheckedGroup = this.groupList.filter(group => group.checked).length;
    }
    getUserList(state: string): void {
        this.initLoadingStates(state);
        const param: UserInfoRequest = {
            search: this.optionsUser.search,
            page: this.optionsUser.page,
            isStudent: true,
            isTeacher: false,
            limit: this.optionsUser.limit,
            trash: false
        };
        this.subscriptionsAdd(
            this.authPublicService.apiPublicAuthUserInfoPost(param).subscribe(response => {
                if (!response) return this.errorsLoadingStates(state, '');
                this.userList = response;
                this.mapCheckedData();
                this.successLoadingStates(state);
            })
        );
    }
    getGroupList(state: string): void {
        this.initLoadingStates(state);
        const param: GroupInfoRequest = {
            search: this.optionsGroup.search,
            page: this.optionsGroup.page,
            limit: this.optionsGroup.limit,
            trash: false
        };
        this.authPublicService.apiPublicAuthGroupInfoPost(param).subscribe(response => {
            if (!response?.length) return this.errorsLoadingStates(state, '');
            this.groupList = response;
            this.mapCheckedData();
            this.successLoadingStates(state);
        });
    }

    private mapCheckedData(): void {
        if (this.userList.length && this.userIdList.length) {
            this.userList.forEach(student => {
                student.checked = false;
                this.userIdList.forEach(id => {
                    if (student.id === id) student.checked = true;
                });
            });
        }
        if (this.groupList.length && this.groupIdList.length) {
            this.groupList.forEach(group => {
                group.checked = false;
                this.groupIdList.forEach(id => {
                    if (group.id === id) group.checked = true;
                });
            });
        }
    }
    ngOnInit(): void {
        super.ngOnInit();
        this.optionsUser.setLimit(1000);
        this.optionsGroup.setLimit(1000);
        this.getUserList('getUserList');
        this.getGroupList('getGroupList');
        this.optionsUser.searchDataUpdate.pipe(debounceTime(this.optionsUser.debounceTimeSearch), distinctUntilChanged()).subscribe(value => {
            this.getUserList('searchUser');
        });
        this.optionsGroup.searchDataUpdate.pipe(debounceTime(this.optionsGroup.debounceTimeSearch), distinctUntilChanged()).subscribe(value => {
            this.getUserList('searchGroup');
        });
        this.totalCheckedStudent = this.userIdList.length;
        this.totalCheckedGroup = this.groupIdList.length;
    }
}

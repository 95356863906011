<div class="d-flex align-items-center mb-2">
    <div><ng-content></ng-content></div>
    <div class="ml-auto">
        <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" [(ngModel)]="viewMode">
            <label ngbButtonLabel class="btn-sm btn-outline-primary" (click)="toggleView('list')" rippleEffect>
                <input ngbButton type="radio" value="list" />
                <lucide-icon name="list" size="18"></lucide-icon>
            </label>
            <label ngbButtonLabel class="btn-sm btn-outline-primary" (click)="toggleView('grid')" rippleEffect>
                <input ngbButton type="radio" value="grid" />
                <lucide-icon name="layout-grid" size="18"></lucide-icon>
            </label>
        </div>
    </div>
</div>
<div class="view-container">
    <ng-container *ngIf="viewMode === 'grid'">
        <ng-container *ngTemplateOutlet="gridViewTemplate"></ng-container>
    </ng-container>
    <ng-container *ngIf="viewMode === 'list'">
        <ng-container *ngTemplateOutlet="listViewTemplate"></ng-container>
    </ng-container>
</div>

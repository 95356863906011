import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';

import {CoreSidebarModule} from '@core/components/core-sidebar/core-sidebar.module';

import {CoreThemeCustomizerComponent} from '@core/components/theme-customizer/theme-customizer.component';
import {LucideAngularModule, icons} from 'lucide-angular';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelPropagation: false,
};

@NgModule({
    declarations: [CoreThemeCustomizerComponent],
    imports: [CommonModule, FormsModule, ReactiveFormsModule, FlexLayoutModule, PerfectScrollbarModule, CoreSidebarModule, LucideAngularModule.pick(icons)],
    providers: [
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
        },
    ],
    exports: [CoreThemeCustomizerComponent],
})
export class CoreThemeCustomizerModule {}

<div class="card mb-2">
    <img class="card-img-top" [src]="thumb" [alt]="course.title" />
    <div class="card-body">
        <a class="btn-primary-text" (click)="redirectToDetail.emit()">
            <h4 class="card-title" style="min-height: 45px">{{ course.title }}</h4>
        </a>
        <div class="card-controls">
            <div class="card-price">
                <div class="card-price-discount price-org" *ngIf="course.priceDiscount && course.priceDiscount > 0">
                    <lucide-icon name="circle-dollar-sign" size="18"></lucide-icon>
                    <span>{{ (course.priceDiscount | formatMoney) + ' ' + 'VNĐ' }}</span>
                </div>
                <div class="card-price-org" [ngClass]="{'price-line-through': course.priceDiscount && course.priceDiscount > 0, 'price-org': !course.priceDiscount}">
                    <lucide-icon name="circle-dollar-sign" size="18"></lucide-icon>
                    <span>{{ course.price > 0 ? (course.price | formatMoney) + ' ' + 'VNĐ' : 'Miễn phí' }}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="menu">
        <ng-container *ngTemplateOutlet="menuTemplate; context: {$implicit: course, index}"></ng-container>
    </div>
</div>

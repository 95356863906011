<div class="card mb-2">
    <img class="card-img-top" [src]="thumb" [alt]="exam.name" />
    <div class="card-body">
        <a (click)="redirectToDetail.emit()">
            <h4 class="card-title" style="min-height: 45px">{{ exam.name }}</h4>
        </a>
    </div>
    <div class="menu">
        <ng-container *ngTemplateOutlet="menuTemplate; context: {$implicit: exam, index}"></ng-container>
    </div>
</div>

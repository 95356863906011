import { Directive, ElementRef, EventEmitter, OnDestroy, Output, Renderer2 } from '@angular/core';

@Directive({
    selector: '[scrollTracker]'
})
export class ScrollTrackerDirective implements OnDestroy {
    @Output() scrollingFinished = new EventEmitter<void>();
    private emitted = false;
    private scrollListener: () => void;

    constructor(private el: ElementRef, private renderer: Renderer2) {
        this.scrollListener = this.renderer.listen(this.el.nativeElement, 'scroll', this.onScroll.bind(this));
    }

    private onScroll(): void {
        const element = this.el.nativeElement;
        const paddingBottom = 2;
        if ((element.scrollHeight - element.scrollTop - element.clientHeight < paddingBottom) && !this.emitted) {
            this.emitted = true;
            this.scrollingFinished.emit();
        } else if ((element.scrollHeight - element.scrollTop > element.clientHeight)) {
            this.emitted = false;
        }
    }

    ngOnDestroy(): void {
        if (this.scrollListener) {
            this.scrollListener();
        }
    }
}
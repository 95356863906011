import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UploadPublicService} from 'api/store_public_v1';
import {MyBaseComponent} from 'app/core/my-base.component';
import {getFileMB, isImage} from 'app/helpers/FileManagers';
import {environment} from 'environments/environment';
import {ToastrService} from 'ngx-toastr';
import {DialogCropperComponent} from '../dialogs/dialog-cropper/dialog-cropper.component';

@Component({
    selector: 'app-upload-image',
    templateUrl: './upload-image.component.html',
    styleUrls: ['./upload-image.component.scss']
})
export class UploadImageComponent extends MyBaseComponent {
    @Input() imageUrl: string;
    @Input() name: string;
    @Input() description: string = '';
    @Input() rounded: string = '50%';
    @Input() size: string = '135';
    @Input() width: string = '135';
    @Input() aspectRatio: number = 4 / 4;
    @Output() changeImageUrl = new EventEmitter<string>();
    @Output() removeImageUrl = new EventEmitter<string>();
    constructor(
        private uploadPublicService: UploadPublicService,
        private toastrService: ToastrService,
        private modalService: NgbModal
    ) {
        super();
    }
    removeImage(): void {
        this.removeImageUrl.emit();
    }
    fileChangeEvent(event: Event | DragEvent): void {
        let file: File;
        event instanceof DragEvent ? (file = event.dataTransfer?.files[0]) : (file = (event.target as HTMLInputElement).files[0] as File);
        if (!event) return;
        if (!isImage(file.type)) {
            this.toastrService.error('Vui lòng chọn đúng file ảnh', 'Lỗi', {
                toastClass: 'toast ngx-toastr',
                closeButton: true
            });
            return;
        }
        if (Number(getFileMB(file.size)) > environment.maxUploadImage) {
            this.toastrService.error('Vui lòng chọn file dưới 10mb', 'Lỗi', {
                toastClass: 'toast ngx-toastr',
                closeButton: true
            });
            return;
        }
        const modal = this.modalService.open(DialogCropperComponent, {centered: true});
        modal.componentInstance.imageFileChanged = file;
        modal.componentInstance.aspectRatio = this.aspectRatio;
        modal.closed.subscribe(result => {
            if (!result) return;
            this.changeImageUrl.emit(result);
        });
    }
    ngOnInit(): void {}
}

import { NgModule } from '@angular/core';
import { formatMoneyPipe } from './currency.pipe';


@NgModule({
    imports: [],
    exports: [formatMoneyPipe],
    declarations: [formatMoneyPipe],
    providers: [],
})
export class CurrencyPipeModule { }

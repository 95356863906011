import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {CoreCommonModule} from '@core/common.module';

import {FooterComponent} from 'app/layout/components/footer/footer.component';
import {ScrollTopComponent} from 'app/layout/components/footer/scroll-to-top/scroll-top.component';
import {LucideAngularModule, icons} from 'lucide-angular';

@NgModule({
    declarations: [FooterComponent, ScrollTopComponent],
    imports: [RouterModule, CoreCommonModule, LucideAngularModule.pick(icons)],
    exports: [FooterComponent],
})
export class FooterModule {}

<div class="modal-header">
    <h5 class="modal-title" id="myModalLabel160">
        <lucide-icon [name]="iconName" *ngIf="iconName" [ngClass]="{ 'mr-50': true }"></lucide-icon>
        <span [innerHTML]="title"></span>
    </h5>
    <button type="button" class="close" (click)="dismiss()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" tabindex="0" ngbAutofocus *ngIf="content">
    <span [innerHTML]="content"></span>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary mr-50" (click)="dismiss()" rippleEffect>{{ 'Hủy' }}</button>
    <button type="button" class="btn btn-primary" rippleEffect (click)="confirmExit()">{{ 'Xác nhận' }}</button>
</div>

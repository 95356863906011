import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class LayoutHorizontalStudentService {
    private isShowLayout: boolean = true;
    getIsShowLayout(): boolean{
        return this.isShowLayout;
    }
    setIsShowLayout(status: boolean): void{
        this.isShowLayout = status;
    }
    constructor() { }
    
}
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ColumnMode} from '@swimlane/ngx-datatable';
import {environment} from 'environments/environment';
import {Subscription, TeardownLogic} from 'rxjs';

@Component({
    selector: 'my-base',
    template: ``
})
export class MyBaseComponent implements OnInit {
    status: LoadingStatus = {loading: false, errorMessage: '', mustShowErrorBox: false};
    loadingStates: {[key: string]: LoadingStatus} = {};
    columnMode = ColumnMode;

    private subscriptions: Subscription = new Subscription();
    private oldUrl: string = '';
    private counter: number = 0;
    private oldMilliSeconds = 0;
    constructor() {}

    subscriptionsAdd(teardown: TeardownLogic): void {
        this.subscriptions.add(teardown);
    }
    initStatus(): void {
        this.status = {loading: true, errorMessage: '', mustShowErrorBox: false};
    }
    successStatus(successMessage?: string, snack?: boolean): void {
        this.status = {loading: false, errorMessage: '', mustShowErrorBox: false};
    }
    errorStatus(errorMessage: string, snack?: boolean): void {
        this.status = {loading: false, errorMessage, mustShowErrorBox: false};
    }
    stopStatus(): void {
        this.status = {loading: false, errorMessage: '', mustShowErrorBox: false};
    }
    getMyParam(myActivatedRoute: ActivatedRoute, param: string): string | undefined {
        if (myActivatedRoute.snapshot.paramMap.get(param) != null) {
            var paramValue = myActivatedRoute.snapshot.paramMap.get(param) as string;
            return paramValue;
        } else {
            return undefined;
        }
    }
    setLoadingStates(key: string, state: LoadingStatus): void {
        this.loadingStates[key] = state;
    }
    initLoadingStates(key: string): void {
        this.loadingStates[key] = {loading: true, errorMessage: '', mustShowErrorBox: false};
    }
    successLoadingStates(key: string): void {
        this.loadingStates[key] = {loading: false, errorMessage: '', mustShowErrorBox: false};
    }
    errorsLoadingStates(key: string, msg: string): void {
        this.loadingStates[key] = {loading: false, errorMessage: msg, mustShowErrorBox: false};
    }
    stopLoadingStates(key: string): void {
        this.loadingStates[key] = {loading: false, errorMessage: '', mustShowErrorBox: false};
    }
    subtractArrays<T extends {id: number}>(arr: T[], selected: T[]): T[] {
        return arr.filter(user => !selected.some(selectedUser => selectedUser.id === user.id));
    }
    private checkCounter(): void {
        let currentUrl = window.location.href;
        this.counter = this.oldUrl == currentUrl ? this.counter + 1 : 0;
        this.oldUrl = currentUrl;

        let currentMilliSeconds = new Date().getTime();
        if (currentMilliSeconds - this.oldMilliSeconds < 600) return;
        this.oldMilliSeconds = currentMilliSeconds;

        let currentTitle = document.title;
        let specialChars = '--';
        if (currentTitle.indexOf(specialChars) > 0) currentTitle = currentTitle.substring(currentTitle.indexOf(specialChars) + specialChars.length);
        document.title = this.counter + specialChars + currentTitle;
    }
    ngOnInit() {}
    ngDoCheck(): void {
        if (!environment.production) this.checkCounter();
    }
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}

export interface LoadingStatus {
    loading: boolean;
    errorMessage: string;
    mustShowErrorBox: boolean;
}

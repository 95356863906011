import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreLoadingComponent } from './core-loading.component';



@NgModule({
  declarations: [
    CoreLoadingComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [CoreLoadingComponent]
})
export class CoreLoadingModule { }

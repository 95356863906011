import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'environments/environment';
import {ToastrService} from 'ngx-toastr';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {CommonService} from '../services/common.service';
import {RedirectService} from '../services/redirect.service';
import {UserService} from '../services/user.service';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
    constructor(
        private commonService: CommonService,
        private redirectService: RedirectService,
        private userService: UserService,
        private toastrService: ToastrService,
    ) {}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.commonService.checkStartWithApi(request.url)) {
            return next.handle(request).pipe(
                map(event => {
                    if (event instanceof HttpResponse) {
                        let bodyData = event.body;
                        this.modifyBody(request.urlWithParams, bodyData);
                        if (event instanceof HttpResponse) {
                            event = event.clone({body: this.modifyBody(request.urlWithParams, event.body)});
                        }
                        return event;
                    }
                    return event;
                }),
                catchError((error: HttpErrorResponse) => {
                    let isThrowException = this.commonService.checkStartWithApi(request.url);
                    return this.handleError(isThrowException, true, error);
                }),
            );
        } else {
            return next.handle(request);
        }
    }
    private modifyBody(url: string, response: any): void {
        if (response && response.success == 0 && response.code == 401) {
            this.userService.logout();
            this.redirectService.login();
        } else {
            if (response && response.accountId && response.rememberToken) {
                this.userService.saveUserObj(response, response.rememberToken);
            }
        }
    }
    private replaceApiDomain(message: string): string {
        return message.replace(environment.apiAuthUrl, '').replace(environment.apiQuestionUrl, '').replace(environment.apiExamUrl, '');
    }
    private handleError(ownApi: boolean, p0: boolean, error: HttpErrorResponse): Observable<any> {
        this.toastrService.error(this.getMsgErr(error.status), 'Lỗi', {
            toastClass: 'toast ngx-toastr',
            closeButton: true,
        });
        if (error && error.status == 401 && ownApi) {
            this.userService.logout();
            this.redirectService.login();
        }
        return of(
            new HttpResponse({
                status: error.status,
                body: undefined,
            }),
        );
    }
    private getMsgErr(status: number): string {
        let msgErr = '';
        switch (status) {
            case 400:
                msgErr = 'Yêu cầu không hợp lệ. Vui lòng kiểm tra dữ liệu bạn đã gửi!';
                break;
            case 401:
                msgErr = 'Bạn không có quyền truy cập vào tài nguyên này. Vui lòng đăng nhập!';
                break;
            case 500:
                msgErr = 'Có lỗi xảy ra trên máy chủ. Vui lòng thử lại sau!';
                break;
            case 502:
                msgErr = 'Máy chủ gặp sự cố khi nhận được phản hồi từ máy chủ phụ. Vui lòng thử lại sau!';
                break;
            case 503:
                msgErr = 'Dịch vụ hiện đang không khả dụng. Vui lòng thử lại sau!';
                break;

            default:
                break;
        }
        return msgErr;
    }
}

<!-- Menu header -->
<div class="navbar-header">
    <ul class="nav navbar-nav flex-row">
        <li class="nav-item mr-auto">
            <!-- App Branding -->
            <a class="navbar-brand d-flex align-items-center flex-nowrap" routerLink="/student">
                <img src="https://s3.evgcloud.net/liveazotastoragept072024/avatar/m09_2024/d27/89262003/c5615c103104df8e3ef3d0bd1af52303.png" alt="brand-logo" style="width: 50px; margin: -4px" />
            </a>
        </li>

        <!-- Menu Toggler -->
        <li class="nav-item nav-toggle">
            <a class="nav-link modern-nav-toggle d-none d-xl-block pr-0" (click)="toggleSidebarCollapsible()">
                <i [ngClass]="isCollapsed === true ? 'icon-circle' : 'icon-disc'" class="toggle-icon feather font-medium-4 collapse-toggle-icon text-primary"></i>
            </a>
            <a class="nav-link modern-nav-toggle d-block d-xl-none pr-0" (click)="toggleSidebar()">
                <lucide-icon name="x" size="20" [ngClass]="{'font-medium-4 text-primary toggle-icon': true}"></lucide-icon>
            </a>
        </li>
    </ul>
</div>
<!--/ Menu header -->

<!-- Navbar shadow -->
<div class="shadow-bottom" [ngClass]="{'d-block': isScrolled}"></div>

<!-- Main menu -->
<div class="main-menu-content" [perfectScrollbar] (scroll)="onSidebarScroll()">
    <ul class="navigation navigation-main" layout="vertical" core-menu></ul>
</div>
<!--/ Main menu -->

<div class="media flex-column flex-md-row box-file rounded p-2" appDragDropFile (filesChangeEmitter)="fileChangeEvent($event)">
    <div class="avatar-upload" (click)="fileUpload.click()">
        <app-avatar [url]="imageUrl" [name]="name" rounded="{{ rounded }}" size="{{ size }}" width="{{ width }}"></app-avatar>
        <input class="d-none" type="file" (change)="fileChangeEvent($event)" #fileUpload />
    </div>
    <div class="media-body ml-0 mt-1 mt-md-0 ml-md-1">
        <div class="text-primary font-weight-bold">{{ 'Kéo thả file hoặc click vào nút Upload' }}</div>
        <small class="text-muted font-weight-bold">{{ description }}</small>
        <div class="mt-50">
            <button class="btn btn-sm btn-outline-primary p-50" rippleEffect (click)="fileUpload.click()">
                <lucide-icon name="upload" size="16"></lucide-icon>
                <span class="ml-50">{{ 'Upload' }}</span>
            </button>
            <button class="btn btn-sm btn-outline-danger p-50 ml-1" rippleEffect *ngIf="imageUrl" (click)="removeImage()">
                <lucide-icon name="trash-2" size="16"></lucide-icon>
                <span class="ml-50">{{ 'Xóa' }}</span>
            </button>
        </div>
    </div>
</div>

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class RedirectService {
	constructor(
		private router: Router
	) {}
	
	login(): void {
		this.redirectUrl('/auth/login');
	}

	redirectUrl(url: string): void {
		this.router.navigate([url]);
	}

	forRoles(roleKeys: string[]): boolean {
		if (roleKeys.includes('admin')) {
			this.redirectUrl('/manager');
			return false;
		}
		if (roleKeys.includes('student')) {
			this.redirectUrl('/student');
			return false;
		}
		
		return true;
	}

	redirectDetailCourse(id: number): void {
		this.redirectUrl(`/student/course-detail/${id}`);
	}
}

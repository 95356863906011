import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FocusDirective } from './auto-focus/auto-focus.directive';
import { AutoWidthDirective } from './auto-width-input/auto-width.directive';
import { CurrencyFormatDirective } from './currency/currency-format.directive';
import { DragDropFileDirective } from './drag-drop-file/drag-drop-file.directive';
import { DraggableDirective } from './drag-drop/draggable.directive';
import { DroppableDirective } from './drag-drop/dropgable.directive';
import { NumberOnlyDirective } from './only-number/only-number-input.directive';
import { ScrollTrackerDirective } from './scroll-tracker/scroll-tracker.directive';
import { IsDisabledTooltipDirective } from './truncate/is-disabled-tooltip.directive';



@NgModule({
    declarations: [DraggableDirective, DroppableDirective, AutoWidthDirective, DragDropFileDirective, IsDisabledTooltipDirective, ScrollTrackerDirective, FocusDirective, CurrencyFormatDirective, NumberOnlyDirective],
    exports: [DraggableDirective, DroppableDirective, AutoWidthDirective, DragDropFileDirective, IsDisabledTooltipDirective, ScrollTrackerDirective, FocusDirective, CurrencyFormatDirective, NumberOnlyDirective],
    imports: [
        CommonModule
    ]
})
export class DirectiveModule { }

//Check file type for uploading
export function isImage(type: string): boolean {
    var filterType =
        /^(?:image\/bmp|image\/cis\-cod|image\/gif|image\/ief|image\/jpeg|image\/jpeg|image\/jpeg|image\/pipeg|image\/png|image\/svg\+xml|image\/tiff|image\/x\-cmu\-raster|image\/x\-cmx|image\/x\-icon|image\/x\-portable\-anymap|image\/x\-portable\-bitmap|image\/x\-portable\-graymap|image\/x\-portable\-pixmap|image\/x\-rgb|image\/x\-xbitmap|image\/x\-xpixmap|image\/x\-xwindowdump|image\/webp)$/i;
    return filterType.test(type);
}
export function isPdfDocument(type: string): boolean {
    var filterType = /^(?:application\/pdf)$/i;
    return filterType.test(type);
}
export function isVideo(type: string): boolean {
    var filterType = /^(?:video)/i;
    return filterType.test(type);
}
export function isVideoUpload(type: string): boolean {
    var filterType1 = /^(?:video\/mp4)/i;
    var filterType2 = /^(?:video\/mov)/i;
    var filterType3 = /^(?:video\/webm)/i;
    var filterType4 = /^(?:video\/quicktime)/i;
    if (filterType1.test(type)) {
        return filterType1.test(type);
    } else if (filterType2.test(type)) {
        return filterType2.test(type);
    } else if (filterType3.test(type)) {
        return filterType3.test(type);
    } else if (filterType4.test(type)) {
        return filterType4.test(type);
    } else {
        return false;
    }
}
export function isAudio(type: string): boolean {
    var filterType = /^(?:audio)/i;
    return filterType.test(type);
}
export function isAudioSupport(type: string): boolean {
    var filterType1 = /^(?:audio\/mpeg)/i;
    var filterType2 = /^(?:audio\/wav)/i;
    if (filterType1.test(type)) {
        return filterType1.test(type);
    } else if (filterType2.test(type)) {
        return filterType2.test(type);
    } else {
        return false;
    }
}
export function isOfficeDocument(type: string): boolean {
    var filterType1 = /^(?:application\/msword)/i;
    var filterType2 = /^(?:application\/vnd.ms-word)/i;
    var filterType3 = /^(?:application\/vnd.openxmlformats)/i;
    var filterType3 = /^(?:application\/vnd.openxmlformats)/i;
    var filterType4 = /^(?:application\/vnd.openxmlformats-officedocument.wordprocessingml.document)/i;
    if (filterType1.test(type)) {
        return filterType1.test(type);
    } else if (filterType2.test(type)) {
        return filterType2.test(type);
    } else if (filterType3.test(type)) {
        return filterType3.test(type);
    } else if (filterType4.test(type)) {
        return filterType4.test(type);
    } else {
        return false;
    }
}
export function isExcelDocument(type: string): boolean {
    const regexExcel = /^([a-z]\w*)(.xls|.XLS|.csv|.CSV|.xlsx|.XLSX)$/;
    const regexExcel2 = /^(?:application\/vnd.openxmlformats-officedocument.spreadsheetml.sheet)/;
    const regexExcel3 = /^(xls|XLS|csv|CSV|xlsx|XLSX)/;
    if (regexExcel.test(type)) {
        return regexExcel.test(type);
    }
    if (regexExcel2.test(type)) {
        return regexExcel2.test(type);
    }
    if (regexExcel3.test(type)) {
        return regexExcel3.test(type);
    }
    return false;
}
export function isWordDocument(type: string): boolean {
    const regexWordDocx = /^(?:application\/vnd.openxmlformats-officedocument.wordprocessingml.document)/;
    const regexWordDoc = /^(?:application\/msword)/;
    if (regexWordDocx.test(type)) {
        return regexWordDocx.test(type);
    }
    if (regexWordDoc.test(type)) {
        return regexWordDoc.test(type);
    }
    return false;
}
export function isPowerpointDocument(type: string): boolean {
    const regexPPT1 = /^(?:application\/powerpoint)/;
    const regexPPT2 = /^(?:application\/vnd.ms-powerpoint)/;
    const regexPPT3 = /^(?:application\/vnd.openxmlformats-officedocument.presentationml.presentation)/;
    if (regexPPT1.test(type)) {
        return regexPPT1.test(type);
    }
    if (regexPPT2.test(type)) {
        return regexPPT2.test(type);
    }
    if (regexPPT3.test(type)) {
        return regexPPT3.test(type);
    }
    return false;
}
export function isNotSupportVideo(type: string): boolean {
    var filterType1 = /^(?:video\/wmv)/i;
    var filterType2 = /^(?:video\/x-ms-wmv)/i;
    if (filterType1.test(type)) {
        return filterType1.test(type);
    } else if (filterType2.test(type)) {
        return filterType2.test(type);
    } else {
        return false;
    }
}
export function isSupportTypeUploadHomework(type: string): boolean {
    if (isImage(type)) {
        return true;
    }
    if (isPdfDocument(type)) {
        return true;
    }
    if (isOfficeDocument(type)) {
        return true;
    }
    if (isVideoUpload(type)) {
        return true;
    }
    if (isAudio(type)) {
        return true;
    }
    return false;
}
export function isSupportTypeUpload(type: string): boolean {
    if (isImage(type)) {
        return true;
    }
    if (isExcelDocument(type)) {
        return true;
    }
    if (isPowerpointDocument(type)) {
        return true;
    }
    if (isPdfDocument(type)) {
        return true;
    }
    if (isVideoUpload(type)) {
        return true;
    }
    if (isAudio(type)) {
        return true;
    }

    return false;
}
export function getFileTypeString(type: string): string {
    if (isImage(type)) {
        return 'image';
    }
    if (isPdfDocument(type)) {
        return 'pdf';
    }
    if (isVideo(type)) {
        return 'video';
    }
    if (isAudio(type)) {
        return 'audio';
    }

    return 'officeapps';
}
export function getFileSizeMB(size: number): number {
    if (size <= 0) return 0;
    size = size / (1024 * 1024);
    return Number(size.toFixed(2));
}

// Compare utils
const CONFIG_COMPRESS_IMAGE = {
    MAX_WIDTH: 1280,
    MAX_HEIGHT: 1280,
    MIME_TYPE: 'image/jpeg',
    QUALITY: 0.9,
};
interface ImageSize {
    width: number;
    height: number;
}
function calculateSize(img: HTMLImageElement, maxWidth: number, maxHeight: number): ImageSize {
    let width = img.width;
    let height = img.height;

    // calculate the width and height, constraining the proportions
    if (width > height) {
        if (width > maxWidth) {
            height = Math.round((height * maxWidth) / width);
            width = maxWidth;
        }
    } else {
        if (height > maxHeight) {
            width = Math.round((width * maxHeight) / height);
            height = maxHeight;
        }
    }

    var returnObj: ImageSize = {width, height};
    return returnObj;
}
function getFileNameWithoutExtension(blob: File): string {
    const fullName = blob.name;
    const lastDotIndex = fullName.lastIndexOf('.');
    if (lastDotIndex !== -1 && lastDotIndex > 0) {
        const nameWithoutExtension = fullName.slice(0, lastDotIndex);
        return nameWithoutExtension;
    }
    return fullName;
}
function compressImageAction(imageFile: File): Promise<File> {
    return new Promise(resolve => {
        const newFileName = getFileNameWithoutExtension(imageFile) + '.jpeg';
        const blobURL = URL.createObjectURL(imageFile);
        const img = new Image();
        img.src = blobURL;
        img.onerror = function (): void {
            URL.revokeObjectURL(this.src);
            resolve(imageFile);
        };
        img.onload = (): void => {
            try {
                URL.revokeObjectURL(img.src);
                var imageSize = calculateSize(img, CONFIG_COMPRESS_IMAGE.MAX_WIDTH, CONFIG_COMPRESS_IMAGE.MAX_HEIGHT);
                const newWidth = imageSize.width;
                const newHeight = imageSize.height;

                const canvas = document.createElement('canvas');
                canvas.width = newWidth;
                canvas.height = newHeight;
                const ctx: any = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, newWidth, newHeight);
                canvas.toBlob(
                    blob => {
                        if (blob != null) {
                            const myFile = new File([blob], newFileName, {type: blob.type});
                            resolve(myFile);
                        } else {
                            resolve(imageFile);
                        }
                    },
                    CONFIG_COMPRESS_IMAGE.MIME_TYPE,
                    CONFIG_COMPRESS_IMAGE.QUALITY,
                );
            } catch (error) {
                resolve(imageFile);
            }
        };
    });
}
export async function compressImage(imageFile: File): Promise<any> {
    try {
        if (isImage(imageFile.type)) {
            var image = await compressImageAction(imageFile);
            return image;
        } else {
            // Đây không phải là ảnh thì next
            return imageFile;
        }
    } catch (ex) {
        return imageFile;
    }
}
export function loadElementImage(link: string): Promise<HTMLImageElement> {
    return new Promise<HTMLImageElement>((resolve, reject) => {
        const imageEl = new Image();
        imageEl.crossOrigin = 'anonymous';
        imageEl.setAttribute('count', '1');
        imageEl.onload = (): void => {
            resolve(imageEl);
        };
        imageEl.onerror = (): void => {
            const countRun = imageEl.getAttribute('count');
            if (countRun && Number(countRun) == 1) {
                imageEl.src = link;
                imageEl.setAttribute('count', '2');
            } else if (countRun && Number(countRun) < 5) {
                imageEl.setAttribute('count', countRun + 1);
                imageEl.src = link + '?t=' + new Date().getTime();
            } else {
                reject(new Error('lang_testbank_render_cant_download_the_image'));
            }
        };
        imageEl.src = link;
    });
}

//Vimeo utils
function getVimeoId(url: string): string | undefined {
    if (!url.match(/https?:\/\/vimeo\.com\/\d+/gi)) return undefined;

    let findVideoId: string | undefined = undefined;
    const regexGetId = /https:\/\/vimeo\.com\/(\d+)/;
    const matchId = url.match(regexGetId);
    if (matchId && matchId[1]) {
        findVideoId = matchId[1];
    }
    return findVideoId;
}
export function isVimeoVideo(url: string): boolean {
    let findVideoId = getVimeoId(url);
    return findVideoId != undefined && findVideoId.trim() != '';
}
export function getVimeoEmbedLink(url: string): string | undefined {
    let findVideoId = getVimeoId(url);
    if (!findVideoId) return undefined;
    return `https://player.vimeo.com/video/${findVideoId}`;
}

//Youtube utils
export function getYoutubeId(url: string): string | undefined {
    const match = url.match(/(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
    return match && match[1] ? match[1] : undefined;
}
export function isYoutubeVideo(url: string): boolean {
    let findVideoId = getYoutubeId(url);
    return findVideoId != undefined && findVideoId.trim() != '';
}
export function getYoutubeEmbedLink(url: string): string | undefined {
    let findVideoId = getYoutubeId(url);
    if (!findVideoId) return undefined;
    return `https://www.youtube.com/embed/${findVideoId}`;
}

//Docs utils
export function isGoogleDocsUrl(url: string): boolean {
    const match = url.match(/(?:docs\.google\.com\/document\/([a-zA-Z0-9_-]))/);
    return match && match[1] ? true : false;
}
export function isDriveUrl(url: string): boolean {
    const match = url.match(/(?:drive\.google\.com\/([a-zA-Z0-9_-]))/);
    return match && match[1] ? true : false;
}
export function isDropBoxUrl(url: string): boolean {
    const match = url.match(/(?:dropbox\.com\/([a-zA-Z0-9_-]))/);
    return match && match[1] ? true : false;
}

//Extract googleMap url from embedCode
export function getGoogleMapUrl(embedCode: string): string | undefined {
    let url: string | undefined = undefined;
    let srcPattern = /<iframe.*?src=["'](.*?)["'].*?>/;
    let match = embedCode.match(srcPattern);
    if (match && match[1]) {
        url = match[1];
    }
    if (!url) return undefined;

    var pattern1 = /https:\/\/www\.google\.com\/maps\/embed.*/;
    var pattern2 = /https:\/\/www\.google\.vn\/maps\/embed.*/;
    if (url.match(pattern1) || url.match(pattern2)) return url;
    return undefined;
}

//WhiteList embedIframeSrc
export function isWhiteListEmbedVideoUrl(url: string): boolean {
    return isYoutubeVideo(url) || isVimeoVideo(url);
}
export function getWhiteListEmbedVideoUrl(url: string): string | undefined {
    let vimeoUrl = getVimeoEmbedLink(url);
    if (vimeoUrl != undefined) return vimeoUrl;

    let youtubeUrl = getYoutubeEmbedLink(url);
    if (youtubeUrl) return youtubeUrl;

    return undefined;
}
export function isWhiteListDocsUrl(url: string): boolean {
    return isGoogleDocsUrl(url) || isDropBoxUrl(url) || isDriveUrl(url);
}

export function getFileMB(size: number): string | number {
    if (size && size > 0) {
        size = size / (1024 * 1024);
        return size.toFixed(2);
    }
    return 0;
}
export function getFileUrl(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = event => {
            resolve(event.target.result);
        };
        reader.onerror = error => {
            reject(error);
        };
    });
}
export function dataURItoBlob(base64: string): Blob {
    try {
        const base64Index = base64.indexOf(',') + 1;
        const base64Data = base64.substring(base64Index);
        const byteString = atob(base64Data);
        const mimeType = base64.substring(5, base64.indexOf(';'));
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);

        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([int8Array], {type: mimeType});
        return blob;
    } catch (error) {
        console.error('Error converting base64 to File:', error);
        throw error;
    }
}

import { Component, Input, TemplateRef } from '@angular/core';
import { MyBaseComponent } from 'app/core/my-base.component';
import { BrowserStorageService } from 'app/core/services/browser.storage.service';

@Component({
    selector: 'app-view-toggle',
    templateUrl: './view-toggle.component.html',
    styleUrls: ['./view-toggle.component.scss'],
})
export class ViewToggleComponent extends MyBaseComponent {
    @Input() gridViewTemplate!: TemplateRef<any>;
    @Input() listViewTemplate!: TemplateRef<any>;
    @Input() storageKey!: string;
    viewMode: 'grid' | 'list' = 'list';
    constructor(private browserStorageService: BrowserStorageService) {
        super();
    }
    toggleView(mode: 'grid' | 'list') {
        this.viewMode = mode;
        this.browserStorageService.saveSimpleStorage(this.storageKey, mode);
    }

    ngOnInit(): void {
        super.ngOnInit();
        if (!this.storageKey) {
            console.error('ViewToggleComponent requires a unique storageKey input.');
            return;
        }
        const savedViewMode = this.browserStorageService.getSimpleStorage(this.storageKey);
        if (savedViewMode === 'grid' || savedViewMode === 'list') {
            this.viewMode = savedViewMode;
        } else {
            this.viewMode = 'grid';
            this.browserStorageService.saveSimpleStorage(this.storageKey, this.viewMode);
        }
    }
}

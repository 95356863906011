export const environment = {
    production: false,
    maxUploadImage: 10, // MB
    hmr: false,
    apiAuthUrl: 'https://dev-tqb-auth.nvteam.io.vn',
    apiQuestionUrl: 'https://dev-tqb-question.nvteam.io.vn',
    apiExamUrl: 'https://dev-tqb-exam.nvteam.io.vn',
    apiStoreUrl: 'https://dev-tqb-store.nvteam.io.vn',
    parserUrl: 'https://dev-tqb-parser.nvteam.io.vn',
    apiCourseUrl: 'https://dev-tqb-course.nvteam.io.vn',
    tinyMceScriptSrc: 'https://cdnjs.cloudflare.com/ajax/libs/tinymce/7.1.0/tinymce.min.js',
    tinyApiKey: '5v0gn8yrex89mq64v010z3kakallxp2joy34camua48sykxz',
};

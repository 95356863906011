// droppable.directive.ts
import { Directive, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2 } from '@angular/core';
import { DragDropService } from './drag-drop.service';

@Directive({
    selector: '[appDroppable]',
})
export class DroppableDirective {
    @Input() appDroppable: any;
    @Output() itemDropped = new EventEmitter<any>();

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        private dragDropService: DragDropService
    ) { }

    @HostListener('mouseenter', ['$event'])
    onDragEnter(event: MouseEvent): void {
        event.preventDefault();
        if (this.appDroppable) {
            this.renderer.setStyle(this.el.nativeElement, 'border', '1px dashed #862263');
            this.renderer.setStyle(this.el.nativeElement, 'background', '#86226312');
        }
    }

    @HostListener('mouseleave', ['$event'])
    onDragLeave(event: MouseEvent): void {
        event.preventDefault();
        this.renderer.removeStyle(this.el.nativeElement, 'border');
        this.renderer.removeStyle(this.el.nativeElement, 'background');
    }

    @HostListener('mouseup', ['$event'])
    onDrop(event: MouseEvent): void {
        event.preventDefault();
        if (this.appDroppable && this.dragDropService.draggedItem) {
            this.itemDropped.emit(this.dragDropService.draggedItem);
            this.renderer.removeStyle(this.el.nativeElement, 'border');
            this.renderer.removeStyle(this.el.nativeElement, 'background');
            this.dragDropService.clearDraggedItem();
        }
    }
}
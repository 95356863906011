<div class="modal-body border-bottom p-1">
    <div class="tool-list">
        <div class="tool-item ml-0">
            <button class="btn-tool" (click)="rotateLeft()">
                <lucide-icon name="rotate-ccw" size="14"></lucide-icon>
                <span class="ml-50 d-none d-md-inline-block">{{ 'Xoay trái' }}</span>
            </button>
        </div>
        <div class="tool-item">
            <button class="btn-tool" (click)="rotateRight()">
                <lucide-icon name="rotate-cw" size="14"></lucide-icon>
                <span class="ml-50 d-none d-md-inline-block">{{ 'Xoay phải' }}</span>
            </button>
        </div>
        <div class="tool-item">
            <button class="btn-tool" (click)="flipHorizontal()">
                <lucide-icon name="flip-horizontal-2" size="14"></lucide-icon>
                <span class="ml-50 d-none d-md-inline-block">{{ 'Lật ngang' }}</span>
            </button>
        </div>
        <div class="tool-item">
            <button class="btn-tool" (click)="flipVertical()">
                <lucide-icon name="flip-vertical-2" size="14"></lucide-icon>
                <span class="ml-50 d-none d-md-inline-block">{{ 'Lật dọc' }}</span>
            </button>
        </div>
    </div>
</div>
<div class="modal-body custom-scroll p-1" tabindex="0">
    <image-cropper
        [imageFileChanged]="imageFileChanged"
        [maintainAspectRatio]="true"
        [aspectRatio]="aspectRatio"
        [resizeToWidth]="128"
        [cropperMinWidth]="128"
        [onlyScaleDown]="true"
        [roundCropper]="false"
        format="png"
        outputType="base64"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        (cropperReady)="cropperReady()"
        (loadImageFailed)="loadImageFailed()"
        [style.display]="showCropper ? null : 'none'"
    ></image-cropper>
</div>
<div class="modal-footer p-1">
    <button type="button" class="btn btn-secondary mr-50" (click)="dismiss()" rippleEffect>{{ 'Hủy' }}</button>
    <button type="button" class="btn btn-primary btn-min-width d-flex align-items-center" rippleEffect (click)="uploadImage()">
        <span class="mr-50">{{ 'Xác nhận' }}</span>
        <core-loading size="18" *ngIf="loadingStates?.['uploadImage']?.loading"></core-loading>
    </button>
</div>

import { NgModule } from '@angular/core';
import { CdnUrlPipe } from './cdn.pipe';


@NgModule({
    imports: [],
    exports: [CdnUrlPipe],
    declarations: [CdnUrlPipe],
    providers: [],
})
export class CdnPipeModule { }

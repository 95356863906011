import { Directive, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import { CommonService } from 'app/core/services/common.service';

@Directive({
	selector: '[currencyFormat]'
})
export class CurrencyFormatDirective implements OnInit {
	currencyChars = new RegExp('[.,]', 'g');
	constructor(public el: ElementRef, public renderer: Renderer2, private commonService: CommonService) { }

	ngOnInit(): void {
		this.format(this.el.nativeElement.value);
	}
	@HostListener('input', ['$event.target.value']) onInput(e: string): void {
		this.format(e);
	}
	@HostListener('paste', ['$event']) onPaste(event: ClipboardEvent): void {
		event.preventDefault();
		this.format(event.clipboardData?.getData('text/plain') ?? '');
	}
	format(val: string): void {
		const numberFormat = parseInt(String(val).replace(this.currencyChars, ''));
		const currency = this.commonService.formatMoney(numberFormat.toString());
		this.renderer.setProperty(this.el.nativeElement, 'value', currency);
	}
}

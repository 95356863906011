import {Component, HostListener, Input} from '@angular/core';

@Component({
    selector: 'app-drawer',
    templateUrl: './drawer.component.html',
    styleUrls: ['./drawer.component.scss'],
})
export class DrawerComponent {
    @Input() isOpen: boolean = false;
    @Input() size: string = '300px';
    @Input() mode: 'side' | 'over' | 'push' = 'side';
    isMobile: boolean = window.innerWidth < 768;
    width: string = '';

    @HostListener('window:resize', ['$event'])
    onResize(event: Event) {
        this.isMobile = (event.target as Window).innerWidth < 768;
        this.mode = this.isMobile ? 'over' : 'side';
        this.width = this.isMobile ? '320px' : this.size;
    }

    open() {
        this.isOpen = true;
    }
    toggle() {
        this.isOpen = !this.isOpen;
    }

    close() {
        this.isOpen = false;
    }
    ngOnInit(): void {
        this.mode = this.isMobile ? 'over' : 'side';
        this.width = this.isMobile ? '300px' : this.size;
    }
}

import {Injectable} from '@angular/core';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';
import {environment} from 'environments/environment';
import * as sanitize from 'sanitize-html';
import {ScriptJsForm} from '../interfaces/common/scriptJsForm';
import {ConfigRenderService} from './config.render.service';
declare const MathJax: any;
declare const window: any;
declare const MathJaxConfig: any;
declare const sourceUrlMathJax = 'https://239114911.e.cdneverest.net/cdnazota/storage_public/azota_assets/js/tex-mml-chtml/tex-mml-chtml.js';

@Injectable({providedIn: 'root'})
export class CommonService {
    loadMathJax: boolean = false;
    constructor() {}
    base64ObjHeader(data: string): string {
        return btoa(data);
    }
    jsonParse<T>(json: string): T | undefined {
        try {
            return JSON.parse(json) as T;
        } catch (error) {
            return undefined;
        }
    }
    delay(s: number) {
        return new Promise<void>(resolve => {
            return setTimeout(() => {
                resolve();
            }, s * 1000);
        });
    }
    snackSuccess(message: string, btn_text?: string): void {}
    snackError(message: string, btn_text?: string): void {}
    snackWarning(message: string, btn_text?: string): void {}
    snackChoiceAnswer(message: string, btn_text?: string): void {}
    startChoseImage(): Promise<string> {
        return new Promise((resolve, reject) => {
            window.CKFinder.popup({
                chooseFiles: true,
                onInit: function (finder) {
                    finder.on('files:choose', function (evt) {
                        var file = evt.data.files.first();
                        resolve(file.getUrl());
                    });

                    finder.on('file:choose:resizedImage', function (evt) {
                        resolve(evt.data.resizedUrl);
                    });
                },

                connectorPath: 'https://cdn-paloma.nvanh01.com/ckfinder/connector',
            });
        });
    }
    checkStartWithApi(url: string): boolean {
        return (
            url.startsWith(environment.apiAuthUrl) ||
            url.startsWith(environment.apiExamUrl) ||
            url.startsWith(environment.apiStoreUrl) ||
            url.startsWith(environment.apiCourseUrl) ||
            url.startsWith(environment.apiQuestionUrl)
        );
    }
    convertMathJaxContent(content: string | undefined, config?: ConfigRenderService): Promise<string> {
        return new Promise<string>(resolve => {
            if (content == undefined) return resolve('');
            if (!config) config = new ConfigRenderService();
            if (config.getMaxJaxRenderMode() !== '*') return resolve(content);

            const el = document.createElement('div');
            el.innerHTML = this.removeXssInHtml(content);
            if (MathJax.typeset) {
                MathJax.typeset([el]);
                resolve(this.removeMathJax(el.innerHTML));
            } else {
                if (this.loadMathJax) return resolve(content);

                this.loadMathJax = true;
                this.loadMathJaxWhenEmpty(el)
                    .then(el => {
                        resolve(this.removeMathJax(el.innerHTML));
                    })
                    .catch(() => {
                        this.loadMathJax = false;
                        resolve(content);
                    });
            }
        });
    }
    private removeXssInHtml(value: string | undefined): string {
        //Not allow href in <a tag
        if (value === undefined) {
            return '';
        }
        return sanitize(value, {
            allowedTags: false,
            allowedAttributes: {
                img: ['src', 'alt', 'width', 'height', 'style'],
                // List attribute mathjax
                container: ['jax', 'aria-hidden'],
                maction: ['actiontype', 'selection'],
                math: ['display', 'mode', 'xmlns'],
                menclose: ['notation'],
                // 'merror': [],
                mfenced: ['close', 'open', 'separators'],
                mfrac: ['bevelled', 'denomalign', 'linethickness', 'numalign'],
                // 'mi': ['*'],
                // 'mmultiscripts': ['subscriptshift', 'superscriptshift'],
                // 'mn': ['*'],
                mo: ['accent', 'fence', 'lspace', 'maxsize', 'minsize', 'movablelimits', 'rspace', 'separator', 'stretchy', 'symmetric'],
                mover: ['accent', 'align '],
                mpadded: ['depth', 'height', 'lspace', 'voffset', 'width'],
                // 'mphantom': ['*'],
                // 'mroot': ['*'],
                // 'mrow': ['*'],
                ms: ['lquote', 'rquote'],
                mspace: ['depth', 'height', 'width'],
                // 'msqrt': ['*'],
                // 'mstyle': ['scriptminsize', 'scriptsizemultiplier'],
                // 'msub': ['*'],
                // 'msubsup': ['subscriptshift', 'superscriptshift'],
                // 'msup': ['subscriptshift'],
                mtable: ['align', 'columnalign', 'columnlines', 'columnspacing', 'frame', 'framespacing', 'rowalign', 'rowlines'],
                mtd: ['columnalign', 'columnspan', 'rowalign', 'rowspan'],
                // 'mtext': ['*'],
                mtr: ['columnalign', 'rowalign'],
                munder: ['accentunder', 'align'],
                munderover: ['accent', 'accentunder', 'align'],
                // 'semantics': ['definitionURL', 'encoding', 'cd', 'name', 'src'],
                // Allow all
                '*': ['class', 'style', 'id', 'size'],
            },
            allowedSchemes: ['data', 'http', 'https'],
            allowVulnerableTags: true,
            disallowedTagsMode: 'escape',
        });
    }
    private removeMathJax(value: string): string {
        let res: string | undefined = value;
        res = this.replaceAll(res, '<mjx-nstrut></mjx-nstrut><mjx-mi class="mjx-i" size="s">', '<mjx-nstrut></mjx-nstrut><mjx-mi class="mjx-i">');
        res = this.replaceAll(res, '<mjx-dstrut></mjx-dstrut><mjx-mi class="mjx-i" size="s">', '<mjx-dstrut></mjx-dstrut><mjx-mi class="mjx-i">');
        res = this.replaceAll(res, '<mjx-nstrut></mjx-nstrut><mjx-mrow size="s">', '<mjx-nstrut></mjx-nstrut><mjx-mrow>');
        res = this.replaceAll(res, '<mjx-dstrut></mjx-dstrut><mjx-mrow size="s">', '<mjx-dstrut></mjx-dstrut><mjx-mrow>');
        res = this.replaceAll(res, '<mjx-sqrt size="s">', '<mjx-sqrt>');
        res = this.replaceAll(res, '<mjx-msqrt size="s">', '<mjx-msqrt>');
        res = this.replaceAll(res, '<mjx-nstrut></mjx-nstrut><mjx-mn class="mjx-n" size="s">', '<mjx-nstrut></mjx-nstrut><mjx-mn class="mjx-n">');
        res = this.replaceAll(res, '<mjx-dstrut></mjx-dstrut><mjx-mn class="mjx-n" size="s">', '<mjx-dstrut></mjx-dstrut><mjx-mn class="mjx-n">');
        return res ?? '';
    }
    private pushHtmlOutputMathJax(): Promise<boolean> {
        return new Promise<boolean>(resolve => {
            window.MathJax = JSON.parse(JSON.stringify(MathJaxConfig));
            const el = document.getElementById('MathJax-script');
            el?.parentElement?.removeChild(el);
            return this.loadScriptPromise({source: sourceUrlMathJax, name: 'MathJax-script'});
        });
    }
    private loadMathJaxWhenEmpty(el: HTMLDivElement): Promise<HTMLDivElement> {
        return new Promise<HTMLDivElement>((resolve, reject) => {
            this.pushHtmlOutputMathJax().then(status => {
                if (status) {
                    if (MathJax.typeset) {
                        MathJax.typeset([el]);
                        resolve(el);
                    }
                } else {
                    this.snackError('lang_common_please_check_the_network_connection');
                    reject(new Error());
                }
            });
        });
    }
    loadScriptPromise(item: ScriptJsForm, countRetry: number = 3, crossOrigin?: boolean): Promise<boolean> {
        return new Promise(resolve => {
            if (countRetry === 0) {
                resolve(false);
            } else {
                let targetScript = document.getElementById(item.name);
                if (targetScript) {
                    resolve(true);
                } else {
                    let myScript = document.createElement('script');
                    myScript.setAttribute('id', item.name);
                    myScript.async = crossOrigin ?? false;
                    if (crossOrigin) myScript.setAttribute('crossorigin', 'anonymous');
                    document.head.appendChild(myScript);
                    myScript.addEventListener(
                        'load',
                        () => {
                            resolve(true);
                        },
                        false,
                    );
                    myScript.addEventListener('error', () => {
                        // remove element and resolve
                        myScript.parentElement?.removeChild(myScript);
                        countRetry = countRetry - 1;
                        resolve(this.loadScriptPromise(item, countRetry));
                    });

                    myScript.setAttribute('src', item.source);
                }
            }
        });
    }
    replaceAll(target: string | undefined, search: string, replacement: string | undefined): string | undefined {
        if (replacement != undefined && target != undefined) {
            return target?.toString().replace(new RegExp(this.escapeRegExp(search), 'g'), replacement);
        }
        return target;
    }
    private escapeRegExp(str: string): string {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }
    private removeBracketWithChar(source: string, char_remove: string, close_char_remove: string): string {
        const source_check = this.replaceAll(source.replace(/(<[^>]+>)+/gi, ''), ' ', '') ?? '';
        if (source_check.startsWith(char_remove)) {
            const end_index = source.indexOf(close_char_remove);
            if (end_index !== -1) {
                return source.substring(end_index + 1);
            }
        } else {
            const strCheck = source_check.replace(new RegExp('<([^>]+)>([^<]+)<([^>]+)>(.+)'), '$2');
            if (strCheck.trim().startsWith(char_remove) && strCheck.includes(close_char_remove)) {
                source = source.replace(new RegExp('<([^>]+)>([^<]+)<([^>]+)>'), '');
            }
        }
        return source.trim();
    }
    removeBracket(source: string): string {
        for (let i = 0; i < 5; i++) {
            source = this.removeBracketWithChar(source, '(', ')');
            source = this.removeBracketWithChar(source, '[', ']');
        }
        return source.trim();
    }
    willDeleteParseJson(data: string): any {
        try {
            return data ? JSON.parse(data) : null;
        } catch (ex) {
            return null;
        }
    }
    convertVietnameseToEnglish(str: string): string {
        if (str != null && str != undefined) {
            str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
            str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
            str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
            str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
            str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
            str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
            str = str.replace(/đ/g, 'd');
            str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
            str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
            str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
            str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
            str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
            str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
            str = str.replace(/Đ/g, 'D');
        }
        return str;
    }
    //json
    serializeJson<T>(data: T): string {
        return JSON.stringify(data);
    }
    castJsonToObj<T>(jsonStr: string): T | undefined {
        try {
            if (jsonStr) {
                var castDataObj = JSON.parse(jsonStr);
                var castResultData = castDataObj as T;
                return castResultData;
            } else {
                return undefined;
            }
        } catch (ex) {
            return undefined;
        }
    }
    formatMoney(value: string): string {
        value = value.replace(/[^0-9]/g, '');
        var valueConvert = value.replace(/\./g, '');
        var paidAmountConvert = Number(valueConvert);
        return paidAmountConvert?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') ?? '';
    }
    formatMoneyToNumber(value: string): number {
        let money = parseInt(value.replace(/\./g, ''), 10) ?? 0;
        return isNaN(money) ? 0 : money;
    }
    convertDate(birthday: any): Date | string {
        if (!birthday) return new Date();
        const date = new Date(`${birthday.year}-${birthday.month}-${birthday.day}`);
        return date.toLocaleString('sv-SE', {timeZone: 'Asia/Bangkok', hour12: false}).replace(' ', 'T') + '+07:00';
    }
    convertDateTime(birthday: any): Date | string {
        if (!birthday) return new Date();
        const date = new Date(`${birthday.year}-${birthday.month}-${birthday.day}`);
        return date.toLocaleString('sv-SE', {timeZone: 'Asia/Bangkok', hour12: false}).replace(' ', 'T') + '+07:00';
    }
    convertStringToDate(txt: string): NgbDate {
        const [year, month, day] = txt.toString().split('T')[0].split('-');
        return new NgbDate(Number(year), Number(month), Number(day));
    }
}

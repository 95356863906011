<div class="drawer-backdrop" *ngIf="isOpen && mode === 'over'" (click)="close()"></div>
<div class="drawer h-100 card {{ mode }}" [ngStyle]="{width: isOpen ? width : 0}" [class.open]="isOpen" [class.overlay]="mode === 'over'">
    <div class="drawer-content h-100 white-scroll custom-scroll overflow-auto">
        <ng-content></ng-content>
        <div class="drawer-close" *ngIf="isMobile">
            <button class="btn btn-sm btn-flat-dark p-50" (click)="close()">
                <lucide-icon name="x" size="18"></lucide-icon>
            </button>
        </div>
    </div>
</div>

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})

export class DragDropService {
    public draggedItem: any | null = null;

    constructor() { }

    setDraggedItem(item: any): void {
        this.draggedItem = item;
    }

    clearDraggedItem(): void {
        this.draggedItem = null;
    }
}
export * from './configPublic.service';
import { ConfigPublicService } from './configPublic.service';
export * from './eventPublic.service';
import { EventPublicService } from './eventPublic.service';
export * from './examResultStudentPublic.service';
import { ExamResultStudentPublicService } from './examResultStudentPublic.service';
export * from './examStudentPublic.service';
import { ExamStudentPublicService } from './examStudentPublic.service';
export * from './examUserGroupPublic.service';
import { ExamUserGroupPublicService } from './examUserGroupPublic.service';
export * from './takeTestStudentPublic.service';
import { TakeTestStudentPublicService } from './takeTestStudentPublic.service';
export * from './userExamPublic.service';
import { UserExamPublicService } from './userExamPublic.service';
export const APIS = [ConfigPublicService, EventPublicService, ExamResultStudentPublicService, ExamStudentPublicService, ExamUserGroupPublicService, TakeTestStudentPublicService, UserExamPublicService];

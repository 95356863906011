import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'core-loading',
    templateUrl: './core-loading.component.html',
    styleUrls: ['./core-loading.component.scss']
})
export class CoreLoadingComponent implements OnInit {
    @Input() name: TypeLoading = 'oval';
    @Input() size: string | number = '20';
    constructor() { }
    ngOnInit(): void {
    }
}
type TypeLoading = 'audio' | 'ball-triangle' | 'bars' | 'circles' | 'grid' | 'hearts' | 'oval' | 'puff' |
    'rings' | 'spinning-circles' | 'tail-spin' | 'three-dots' | 'bootstrap-spinner' | 'loading-css'
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

import { CoreCommonModule } from '@core/common.module';
import { CoreMenuComponent } from '@core/components/core-menu/core-menu.component';

import { CoreMenuHorizontalCollapsibleComponent } from '@core/components/core-menu/horizontal/collapsible/collapsible.component';
import { CoreMenuHorizontalItemComponent } from '@core/components/core-menu/horizontal/item/item.component';
import { CoreMenuVerticalCollapsibleComponent } from '@core/components/core-menu/vertical/collapsible/collapsible.component';
import { CoreMenuVerticalItemComponent } from '@core/components/core-menu/vertical/item/item.component';
import { CoreMenuVerticalSectionComponent } from '@core/components/core-menu/vertical/section/section.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { LucideAngularModule, icons } from 'lucide-angular';
CoreMenuVerticalSectionComponent;
CoreMenuVerticalItemComponent;
CoreMenuVerticalCollapsibleComponent;

@NgModule({
    imports: [CommonModule, RouterModule, TranslateModule.forChild(), CoreCommonModule, LucideAngularModule.pick(icons), NgbTooltipModule],
    exports: [CoreMenuComponent],
    declarations: [
        CoreMenuComponent,
        CoreMenuVerticalSectionComponent,
        CoreMenuVerticalItemComponent,
        CoreMenuVerticalCollapsibleComponent,
        CoreMenuHorizontalItemComponent,
        CoreMenuHorizontalCollapsibleComponent,
    ],
})
export class CoreMenuModule {}

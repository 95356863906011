import {Subject} from 'rxjs';

export class SearchAndPaginationOptions {
    search: string = '';
    limit: number = 24;
    page: number = 1;
    total: number = 0;
    type: number = 0;
    checkAll: boolean = false;
    paramId: number = 0;
    searchDataUpdate = new Subject<string>();
    debounceTimeSearch: number = 500;
    setLimit(limit: number): void {
        this.limit = limit;
    }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {environment} from 'environments/environment';

@Component({
    selector: 'app-editor',
    templateUrl: './editor.component.html',
    styleUrls: ['./editor.component.scss'],
})
export class EditorComponent implements OnInit {
    @Input() control: FormControl;
    @Input() content: '';
    @Output() listenEvent = new EventEmitter();
    initDataEditor: object;
    apiKey: string = '';
    constructor() {}
    private initConfig(): void {
        this.initDataEditor = {
            height: 450,
            menubar: false,
            draggable_modal: true,
            extended_valid_elements: '*[.*]',
            language: 'vi',
            plugins: ['code', 'fullscreen', 'link'],
            toolbar: 'bold italic backcolor | alignleft aligncenter alignright alignjustify | link | code | fullscreen | tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry',
            external_plugins: {
                tiny_mce_wiris: `/assets/tinymce-math/plugin.min.js`,
            },
            language_url: 'https://cdn.jsdelivr.net/npm/tinymce-langs@1.0.0/langs/vi.min.js',
        };
    }
    ngOnInit(): void {
        this.apiKey = environment.tinyApiKey;
        this.initConfig();
        if (this.control) return;
        this.control = new FormControl(this.content);
        this.control.valueChanges.subscribe(result => {
            this.listenEvent.emit(result);
        });
    }
}

import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { CoreConfigService } from '@core/services/config.service';
import { RedirectService } from 'app/core/services/redirect.service';
import { AuthUserClient, UserService } from 'app/core/services/user.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LayoutHorizontalStudentService } from './services/layout-horizontal-student.service';

@Component({
    selector: 'horizontal-student-layout',
    templateUrl: './horizontal-student.component.html',
    styleUrls: ['./horizontal-student.component.scss']
})
export class HorizontalStudentComponent implements OnInit {
    currentUser: AuthUserClient;
    isShowLayout: boolean = false;
    public coreConfig: any;
    isScrolled: boolean = false;
    private _unsubscribeAll: Subject<any>;
    isCollapsed: any;
    constructor(
        private _coreConfigService: CoreConfigService,
        private userService: UserService,
        private redirectService: RedirectService,
        private coreSidebarService: CoreSidebarService,
        private router: Router,
        private location: Location,
        private layoutHorizontalStudentService: LayoutHorizontalStudentService
    ) {
        this._unsubscribeAll = new Subject();
    }
    toggleSidebar(): void {
        this.coreConfig.layout.menu.collapsed = !this.coreConfig.layout.menu.collapsed;
        this.coreSidebarService.getSidebarRegistry('menu').toggleOpen();
    }
    onSidebarScroll(): void {
    }
    logout(): void {
        this.userService.logout();
        this.redirectService.redirectUrl('/auth/login')
    }
    private checkShowLayout(): boolean {
        const currentUrl = this.location.path();
        if (currentUrl.includes('take-test')) return false;
        return true;
    }
    ngOnInit(): void {
        this.currentUser = this.userService.getUserObj();
        this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
            this.coreConfig = config;
            this.coreConfig.layout.menu.collapsed = true;
        });
        this.isCollapsed = this.coreSidebarService.getSidebarRegistry('menu')?.collapsed;
        this.isShowLayout = this.checkShowLayout();
        this.router.events.subscribe(() => {
            if (this.coreSidebarService.getSidebarRegistry('menu')) {
                this.coreSidebarService.getSidebarRegistry('menu').close();
            }
            this.isShowLayout = this.checkShowLayout();
        });
    }
    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

}

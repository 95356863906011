import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RedirectService } from '../services/redirect.service';
import { UserService } from '../services/user.service';

@Injectable({
	providedIn: 'root',
})
export class AuthManagerGuard {
	constructor(private userService: UserService, private redirectService: RedirectService) {}

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		if (this.userService.isLogin()) {
			const client = this.userService.getUserObj();
			if (client.isAdministrator)
				return true;

			if (this.redirectService.forRoles(client.roles.map(m => m.key))) {
				this.userService.logout();
				this.redirectService.login();
				return false;
			}
		} else {
			this.redirectService.redirectUrl('/auth/login');
			return false;
		}
	}
	canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		return this.canActivate(route, state);
	}
}

import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[isDisabledTooltip]',
    exportAs: 'isDisabled'
})
export class IsDisabledTooltipDirective {
    constructor(private elementRef: ElementRef) { }
    @Input('isDisabledTooltip') isDisabled = false;
    @HostListener('mouseenter') onEnter(): void {
        const element = this.elementRef.nativeElement;
            if (element.offsetWidth == element.scrollWidth) {
                this.isDisabled = true;
            } else {
                this.isDisabled = false;
            }
    }
}
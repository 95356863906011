import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AuthPrivateService } from './api/authPrivate.service';
import { ImportPrivateService } from './api/importPrivate.service';
import { OrganizationGroupPrivateService } from './api/organizationGroupPrivate.service';
import { OrganizationPrivateService } from './api/organizationPrivate.service';
import { PermissionPrivateService } from './api/permissionPrivate.service';
import { StudentPrivateService } from './api/studentPrivate.service';
import { TeacherPrivateService } from './api/teacherPrivate.service';
import { UpsertImportPrivateService } from './api/upsertImportPrivate.service';
import { UserManagerPrivateService } from './api/userManagerPrivate.service';
import { UserPackagePrivateService } from './api/userPackagePrivate.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AuthPrivateService,
    ImportPrivateService,
    OrganizationGroupPrivateService,
    OrganizationPrivateService,
    PermissionPrivateService,
    StudentPrivateService,
    TeacherPrivateService,
    UpsertImportPrivateService,
    UserManagerPrivateService,
    UserPackagePrivateService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}

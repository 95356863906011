<div class="input-group">
    <input
        [ngClass]="ngControl?.valid ? 'ng-valid' : 'ng-invalid'"
        class="form-control"
        (blur)="inputBlur($event)"
        [ngModel]="dateString | date: inputDatetimeFormat"
        (change)="onInputChange($event)"
        [disabled]="disabled"
    />

    <div class="input-group-append">
        <button class="btn btn-outline-secondary feather icon-calendar" [ngbPopover]="calendarContent" [disabled]="disabled" type="button" rippleEffect></button>
    </div>
</div>

<ng-template #calendarContent>
    <div>
        <div *ngIf="!showTimePickerToggle">
            <ngb-datepicker #dp name="datepicker" [ngModel]="datetime" (ngModelChange)="onDateChange($event)"></ngb-datepicker>
            <button class="btn btn-block btn-outline-secondary mt-50" [disabled]="!datetime?.day" [ngbPopover]="timePickerContent" type="button" (click)="toggleDateTimeState($event)">
                <lucide-icon name="clock" size="16"></lucide-icon>
            </button>
        </div>
        <div *ngIf="showTimePickerToggle">
            <button class="btn btn-block btn-outline-secondary mb-50" [ngbPopover]="calendarContent" type="button" (click)="toggleDateTimeState($event)">
                <lucide-icon name="calendar" size="16"></lucide-icon>
            </button>
            <div class="mt-auto">
                <ngb-timepicker
                    #tp
                    name="timepicker"
                    [ngModel]="datetime"
                    (ngModelChange)="onTimeChange($event)"
                    [seconds]="seconds"
                    [hourStep]="hourStep"
                    [minuteStep]="minuteStep"
                    [secondStep]="secondStep"
                ></ngb-timepicker>
            </div>
        </div>
    </div>
</ng-template>

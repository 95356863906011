import {DatePipe} from '@angular/common';
import {AfterViewInit, Component, forwardRef, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl} from '@angular/forms';
import {NgbDatepicker, NgbDateStruct, NgbPopover, NgbPopoverConfig, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import {noop} from 'rxjs';
import {DateTimeModel} from './date-time.model';

@Component({
    selector: 'app-date-time-picker',
    templateUrl: './date-time-picker.component.html',
    styleUrls: ['./date-time-picker.component.scss'],
    providers: [
        DatePipe,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateTimePickerComponent),
            multi: true,
        },
    ],
})
export class DateTimePickerComponent implements ControlValueAccessor, OnInit, AfterViewInit {
    @Input() dateString: string;

    @Input() inputDatetimeFormat = 'd/M/yyyy H:mm:ss';
    @Input() hourStep = 1;
    @Input() minuteStep = 15;
    @Input() secondStep = 30;
    @Input() seconds = true;
    @Input() disabled = false;
    showTimePickerToggle = false;
    datetime: DateTimeModel = new DateTimeModel();
    firstTimeAssign = true;

    @ViewChild(NgbDatepicker) dp: NgbDatepicker;

    @ViewChild(NgbPopover) popover: NgbPopover;

    onTouched: () => void = noop;
    onChange: (_: any) => void = noop;

    ngControl: NgControl;

    constructor(
        private config: NgbPopoverConfig,
        private inj: Injector,
    ) {
        config.autoClose = 'outside';
        config.placement = 'auto';
    }

    ngOnInit(): void {
        this.ngControl = this.inj.get(NgControl);
    }

    ngAfterViewInit(): void {
        this.popover.hidden.subscribe($event => {
            this.showTimePickerToggle = false;
        });
    }

    writeValue(newModel: string) {
        if (newModel) {
            this.datetime = Object.assign(this.datetime, DateTimeModel.fromLocalString(newModel));
            this.dateString = newModel;
            this.setDateStringModel();
        } else {
            this.datetime = new DateTimeModel();
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    toggleDateTimeState($event) {
        this.showTimePickerToggle = !this.showTimePickerToggle;
        $event.stopPropagation();
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    onInputChange($event: any) {
        const value = $event.target.value;
        const dt = DateTimeModel.fromLocalString(value);

        if (dt) {
            this.datetime = dt;
            this.setDateStringModel();
        } else if (value.trim() === '') {
            this.datetime = new DateTimeModel();
            this.dateString = '';
            this.onChange(this.dateString);
        } else {
            this.onChange(value);
        }
    }

    onDateChange($event: string | NgbDateStruct) {
        if (($event as NgbDateStruct).year) {
            $event = `${($event as NgbDateStruct).year}-${($event as NgbDateStruct).month}-${($event as NgbDateStruct).day}`;
        }

        const date = DateTimeModel.fromLocalString($event as string);

        if (!date) {
            this.dateString = this.dateString;
            return;
        }

        if (!this.datetime) {
            this.datetime = date;
        }

        this.datetime.year = date.year;
        this.datetime.month = date.month;
        this.datetime.day = date.day;

        this.dp?.navigateTo({year: this.datetime.year, month: this.datetime.month});
        this.setDateStringModel();
    }

    onTimeChange(event: NgbTimeStruct) {
        this.datetime.hour = event.hour;
        this.datetime.minute = event.minute;
        this.datetime.second = event.second;

        this.setDateStringModel();
    }

    setDateStringModel() {
        this.dateString = this.datetime.toString();

        if (!this.firstTimeAssign) {
            this.onChange(this.dateString);
        } else {
            // Skip very first assignment to null done by Angular
            if (this.dateString !== null) {
                this.firstTimeAssign = false;
            }
        }
    }

    inputBlur($event) {
        this.onTouched();
    }
}

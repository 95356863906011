export class ConfigRenderService {
    private mathJaxMode: string = '';
    private isCropImage: boolean = false;

    getIsCropImage(): boolean {
        return this.isCropImage;
    }
    setMaxJaxRenderMode(mode: 'svg' | '*'): void {
        this.mathJaxMode = mode;
    }
    getMaxJaxRenderMode(): string {
        if (this.mathJaxMode === 'svg') {
            return this.mathJaxMode;
        }
        return '*';
    }

    constructor(isCropImage: boolean = false) {
        this.isCropImage = isCropImage;
    }
}

/**
 * public-v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CommonStatusResponse } from '../model/commonStatusResponse';
import { MoveFolderStructRequest } from '../model/moveFolderStructRequest';
import { StructureStoreCommonDetailResponse } from '../model/structureStoreCommonDetailResponse';
import { StructureStoreCommonResponse } from '../model/structureStoreCommonResponse';
import { UpsertStoreRequest } from '../model/upsertStoreRequest';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PublicStructureService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPublicStructureDelete(body?: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<CommonStatusResponse>;
    public apiPublicStructureDelete(body?: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommonStatusResponse>>;
    public apiPublicStructureDelete(body?: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommonStatusResponse>>;
    public apiPublicStructureDelete(body?: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CommonStatusResponse>('delete',`${this.basePath}/api/public/structure`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param folderId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPublicStructureFolderIdChildIdsGet(folderId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<number>>;
    public apiPublicStructureFolderIdChildIdsGet(folderId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<number>>>;
    public apiPublicStructureFolderIdChildIdsGet(folderId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<number>>>;
    public apiPublicStructureFolderIdChildIdsGet(folderId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (folderId === null || folderId === undefined) {
            throw new Error('Required parameter folderId was null or undefined when calling apiPublicStructureFolderIdChildIdsGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<number>>('get',`${this.basePath}/api/public/structure/${encodeURIComponent(String(folderId))}/child-ids`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param rootId 
     * @param rqUser 
     * @param ckChild 
     * @param rqChild 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPublicStructureGet(rootId?: number, rqUser?: boolean, ckChild?: boolean, rqChild?: boolean, observe?: 'body', reportProgress?: boolean): Observable<StructureStoreCommonResponse>;
    public apiPublicStructureGet(rootId?: number, rqUser?: boolean, ckChild?: boolean, rqChild?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StructureStoreCommonResponse>>;
    public apiPublicStructureGet(rootId?: number, rqUser?: boolean, ckChild?: boolean, rqChild?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StructureStoreCommonResponse>>;
    public apiPublicStructureGet(rootId?: number, rqUser?: boolean, ckChild?: boolean, rqChild?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (rootId !== undefined && rootId !== null) {
            queryParameters = queryParameters.set('rootId', <any>rootId);
        }
        if (rqUser !== undefined && rqUser !== null) {
            queryParameters = queryParameters.set('rqUser', <any>rqUser);
        }
        if (ckChild !== undefined && ckChild !== null) {
            queryParameters = queryParameters.set('ckChild', <any>ckChild);
        }
        if (rqChild !== undefined && rqChild !== null) {
            queryParameters = queryParameters.set('rqChild', <any>rqChild);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<StructureStoreCommonResponse>('get',`${this.basePath}/api/public/structure`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPublicStructureMovePost(body?: MoveFolderStructRequest, observe?: 'body', reportProgress?: boolean): Observable<CommonStatusResponse>;
    public apiPublicStructureMovePost(body?: MoveFolderStructRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommonStatusResponse>>;
    public apiPublicStructureMovePost(body?: MoveFolderStructRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommonStatusResponse>>;
    public apiPublicStructureMovePost(body?: MoveFolderStructRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CommonStatusResponse>('post',`${this.basePath}/api/public/structure/move`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPublicStructureRestorePost(body?: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<CommonStatusResponse>;
    public apiPublicStructureRestorePost(body?: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CommonStatusResponse>>;
    public apiPublicStructureRestorePost(body?: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CommonStatusResponse>>;
    public apiPublicStructureRestorePost(body?: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CommonStatusResponse>('post',`${this.basePath}/api/public/structure/restore`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param rootId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPublicStructureTrashRootIdGet(rootId: number, observe?: 'body', reportProgress?: boolean): Observable<StructureStoreCommonResponse>;
    public apiPublicStructureTrashRootIdGet(rootId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StructureStoreCommonResponse>>;
    public apiPublicStructureTrashRootIdGet(rootId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StructureStoreCommonResponse>>;
    public apiPublicStructureTrashRootIdGet(rootId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (rootId === null || rootId === undefined) {
            throw new Error('Required parameter rootId was null or undefined when calling apiPublicStructureTrashRootIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<StructureStoreCommonResponse>('get',`${this.basePath}/api/public/structure/trash/${encodeURIComponent(String(rootId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPublicStructureUpsertPost(body?: UpsertStoreRequest, observe?: 'body', reportProgress?: boolean): Observable<StructureStoreCommonDetailResponse>;
    public apiPublicStructureUpsertPost(body?: UpsertStoreRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StructureStoreCommonDetailResponse>>;
    public apiPublicStructureUpsertPost(body?: UpsertStoreRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StructureStoreCommonDetailResponse>>;
    public apiPublicStructureUpsertPost(body?: UpsertStoreRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<StructureStoreCommonDetailResponse>('post',`${this.basePath}/api/public/structure/upsert`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}

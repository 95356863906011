/**
 * Convert file content service
 * The convert API description
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Doc2QuestDto { 
    jobName: string;
    appVersion: string;
    hash: string;
    hasError: boolean;
    errMsg: string;
    running: boolean;
    percent: number;
    progressName: string;
    detachType: string;
    mimetype: string;
    originalname: string;
    size: number;
    supported: boolean;
    result: string;
}
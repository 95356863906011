import { Component, OnInit, Optional } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-dialog-confirm',
    templateUrl: './dialog-confirm.component.html',
    styleUrls: ['./dialog-confirm.component.scss']
})
export class DialogConfirmComponent implements OnInit {
    title: string = '';
    iconName: string | undefined;
    content: string | undefined;
    constructor(@Optional() private readonly activeModal: NgbActiveModal) { }
    dismiss(): void {
        if (this.activeModal) this.activeModal.close(false);
    }
    confirmExit(): void {
        if (this.activeModal) this.activeModal.close(true);
    }
    ngOnInit(): void {
    }

}

export * from './configPublic.service';
import { ConfigPublicService } from './configPublic.service';
export * from './eventPublic.service';
import { EventPublicService } from './eventPublic.service';
export * from './publicContentQuestion.service';
import { PublicContentQuestionService } from './publicContentQuestion.service';
export * from './publicCorrectQuestion.service';
import { PublicCorrectQuestionService } from './publicCorrectQuestion.service';
export * from './publicMatrixQuestion.service';
import { PublicMatrixQuestionService } from './publicMatrixQuestion.service';
export const APIS = [ConfigPublicService, EventPublicService, PublicContentQuestionService, PublicCorrectQuestionService, PublicMatrixQuestionService];

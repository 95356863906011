import {Component, Input, OnInit} from '@angular/core';
import {CommonService} from 'app/core/services/common.service';

@Component({
    selector: 'app-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit {
    @Input() url: string | undefined;
    @Input() name: string | undefined;
    @Input() size: string | number;
    @Input() width: string | number;
    @Input() type: 'fill' | 'light' = 'light';
    @Input() index: number;
    @Input() indexCss: number;
    @Input() rounded: string = '50%';
    returnNameAvatar: string = '';
    classCss: string[];
    constructor(private commonService: CommonService) {}
    createNewAvatar(): void {
        try {
            this.name = this.removeInvalidChars(this.name).trim();
        } catch (error) {
            this.returnNameAvatar = this.name;
        }
        const name = this.commonService.convertVietnameseToEnglish(this.name ?? '');
        const nameSplit = name.toUpperCase().split(' ');
        if (nameSplit.length == 1) {
            this.returnNameAvatar = nameSplit[0] ? nameSplit[0].charAt(0) : 'A';
        } else {
            this.returnNameAvatar = nameSplit[0].charAt(0) + nameSplit[nameSplit.length - 1].charAt(0);
        }
    }
    getRandomClass(): void {
        if (this.index !== undefined) {
            this.indexCss = this.index % this.classCss.length;
            return;
        }
        if (!this.indexCss) this.indexCss = Math.floor(Math.random() * this.classCss.length);
    }
    private removeInvalidChars(text?: string): string {
        try {
            return text?.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '') ?? '';
        } catch (error) {
            return text ?? '';
        }
    }
    private initClassCss(): void {
        if (this.type === 'light') this.classCss = ['bg-light-primary', 'bg-light-success', 'bg-light-danger', 'bg-light-warning'];
        if (this.type === 'fill') this.classCss = ['bg-primary', 'bg-success', 'bg-danger', 'bg-warning'];
        this.classCss = ['bg-light-primary'];
    }
    ngOnInit(): void {
        this.createNewAvatar();
        this.initClassCss();
        this.getRandomClass();
    }
}

import {Component, EventEmitter, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {Exam} from 'api/exam_student_public_v1';

@Component({
    selector: 'app-exam-item',
    templateUrl: './exam-item.component.html',
    styleUrls: ['./exam-item.component.scss']
})
export class ExamItemComponent implements OnInit {
    @Input() exam: Exam;
    @Input() index: number = 0;
    @Input() menuTemplate!: TemplateRef<any>;
    @Output() redirectToDetail = new EventEmitter();
    thumb = '';
    constructor() {}
    stringToSlug(str: string): string {
        const from = 'àáãảạăằắẳẵặâầấẩẫậèéẻẽẹêềếểễệđùúủũụưừứửữựòóỏõọôồốổỗộơờớởỡợìíỉĩịäëïîöüûñçýỳỹỵỷ',
            to = 'aaaaaaaaaaaaaaaaaeeeeeeeeeeeduuuuuuuuuuuoooooooooooooooooiiiiiaeiiouuncyyyyy';
        for (let i = 0, l = from.length; i < l; i++) {
            str = str.replace(RegExp(from[i], 'gi'), to[i]);
        }
        str = str
            .toLowerCase()
            .trim()
            .replace(/[^a-z0-9\-]/g, '-')
            .replace(/-+/g, '-');

        return str;
    }
    ngOnInit(): void {
        this.thumb = this.exam.thumb?.length > 0 ? this.exam.thumb : `https://placehold.co/230x160?text=${this.stringToSlug(this.exam.name)}`;
    }
}

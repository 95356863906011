import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthManagerGuard} from './core/guards/auth.manager.guard';
import {AuthStudentGuard} from './core/guards/auth.student.guard';
import {GuestGuard} from './core/guards/guest.guard';
import {HorizontalStudentComponent} from './layout/horizontal-student/horizontal-student.component';
import {VerticalLayoutComponent} from './layout/vertical/vertical-layout.component';

const appRoutes: Routes = [
    {
        path: '',
        redirectTo: '/student/exam-list',
        pathMatch: 'full',
    },
    {
        path: 'auth',
        loadChildren: () => import('./roles/auth/auth.module').then(m => m.AuthModule),
        canActivate: [GuestGuard],
    },
    {
        path: 'manager',
        component: VerticalLayoutComponent,
        loadChildren: () => import('./roles/manager/manager.module').then(m => m.ManagerModule),
        canActivate: [AuthManagerGuard],
    },
    {
        path: 'student',
        component: HorizontalStudentComponent,
        loadChildren: () => import('./roles/student/student.module').then(m => m.StudentModule),
        canActivate: [AuthStudentGuard],
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
            scrollPositionRestoration: 'enabled', // Add options right here
            relativeLinkResolution: 'legacy',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}

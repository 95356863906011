export * from './assignmentPrivate.service';
import { AssignmentPrivateService } from './assignmentPrivate.service';
export * from './courseActionPrivate.service';
import { CourseActionPrivateService } from './courseActionPrivate.service';
export * from './courseChapterPrivate.service';
import { CourseChapterPrivateService } from './courseChapterPrivate.service';
export * from './courseClonePrivate.service';
import { CourseClonePrivateService } from './courseClonePrivate.service';
export * from './coursePrivate.service';
import { CoursePrivateService } from './coursePrivate.service';
export * from './learningPrivate.service';
import { LearningPrivateService } from './learningPrivate.service';
export const APIS = [AssignmentPrivateService, CourseActionPrivateService, CourseChapterPrivateService, CourseClonePrivateService, CoursePrivateService, LearningPrivateService];

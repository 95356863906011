export * from './authPrivate.service';
import { AuthPrivateService } from './authPrivate.service';
export * from './importPrivate.service';
import { ImportPrivateService } from './importPrivate.service';
export * from './organizationGroupPrivate.service';
import { OrganizationGroupPrivateService } from './organizationGroupPrivate.service';
export * from './organizationPrivate.service';
import { OrganizationPrivateService } from './organizationPrivate.service';
export * from './permissionPrivate.service';
import { PermissionPrivateService } from './permissionPrivate.service';
export * from './studentPrivate.service';
import { StudentPrivateService } from './studentPrivate.service';
export * from './teacherPrivate.service';
import { TeacherPrivateService } from './teacherPrivate.service';
export * from './upsertImportPrivate.service';
import { UpsertImportPrivateService } from './upsertImportPrivate.service';
export * from './userManagerPrivate.service';
import { UserManagerPrivateService } from './userManagerPrivate.service';
export * from './userPackagePrivate.service';
import { UserPackagePrivateService } from './userPackagePrivate.service';
export const APIS = [AuthPrivateService, ImportPrivateService, OrganizationGroupPrivateService, OrganizationPrivateService, PermissionPrivateService, StudentPrivateService, TeacherPrivateService, UpsertImportPrivateService, UserManagerPrivateService, UserPackagePrivateService];

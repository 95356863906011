<div class="modal-header">
    <h4 class="modal-title" id="myModalLabel1">
        {{ title }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body p-0" tabindex="0" ngbAutofocus>
    <div class="flex-grow-1" *ngIf="isFullAssignment">
        <ul ngbNav #nav="ngbNav" class="nav-tabs">
            <li ngbNavItem class="w-50%">
                <a ngbNavLink class="py-1">
                    <lucide-icon name="graduation-cap" [size]="20" strokeWidth="2"></lucide-icon>
                    <span class="ml-50">{{ 'Theo học sinh' }}</span>
                </a>
                <ng-template ngbNavContent>
                    <ng-template [ngTemplateOutlet]="assignUser"></ng-template>
                </ng-template>
            </li>
            <li ngbNavItem class="w-50%">
                <a ngbNavLink class="py-1">
                    <lucide-icon name="users" [size]="20" strokeWidth="2"></lucide-icon>
                    <span class="ml-50">{{ 'Theo nhóm' }}</span>
                </a>
                <ng-template ngbNavContent>
                    <ng-template [ngTemplateOutlet]="assignGroup"></ng-template>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
    <div class="flex-grow-1" *ngIf="!isFullAssignment">
        <ng-template [ngTemplateOutlet]="assignUser"></ng-template>
    </div>
</div>
<ng-template #assignUser>
    <div class="card shadow-none student-list m-0">
        <div class="card-body p-1">
            <div class="d-flex align-items-center">
                <div class="d-flex align-items-center">
                    <div class="custom-control custom-checkbox cursor-pointer">
                        <input type="checkbox" class="custom-control-input" id="checkAllAssign" [(ngModel)]="checkAllUserList" (change)="checkAllUser()" />
                        <label class="custom-control-label" for="checkAllAssign">
                            <span class="d-none d-md-inline-block">{{ 'Chọn tất cả' }}</span>
                        </label>
                    </div>
                </div>
                <div class="ml-auto d-flex align-items-center">
                    <div class="input-group input-group-merge w-100">
                        <div class="input-group-prepend cursor-pointer" (click)="getUserList('searchUser')">
                            <span class="input-group-text" style="height: 33px">
                                <lucide-icon name="search" size="16" *ngIf="!loadingStates?.['searchUser']?.loading"></lucide-icon>
                                <core-loading size="16" *ngIf="loadingStates?.['searchUser']?.loading"></core-loading>
                            </span>
                        </div>
                        <input
                            type="text"
                            id="chat-search"
                            style="height: 33px"
                            [(ngModel)]="optionsUser.search"
                            placeholder="Tìm kiếm .."
                            (keyup.enter)="getUserList('searchUser')"
                            (ngModelChange)="optionsUser.searchDataUpdate.next($event)"
                            class="form-control"
                        />
                    </div>
                </div>
            </div>
            <div class="mt-2">
                <div class="font-weight-bold m-0">
                    {{ 'Đã chọn: ' + totalCheckedStudent + ' học sinh' }}
                </div>
            </div>
        </div>
        <div class="card-footer custom-scroll white-scroll p-1">
            <core-loading name="loading-css" size="50" *ngIf="loadingStates['getUserList'].loading; else doneLoadingUser"></core-loading>
            <ng-template #doneLoadingUser>
                <div class="row m-0">
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 p-0" *ngFor="let student of userList; let i = index">
                        <div class="card m-50">
                            <div class="card-body p-0">
                                <label [for]="'student_' + i" class="student-item">
                                    <div class="d-flex align-items-center">
                                        <app-avatar [url]="student.avatar" [name]="student.fullName" [index]="i" size="40"></app-avatar>
                                        <div class="ml-50">
                                            <div>{{ student.fullName }}</div>
                                            <div class="mt-50 text-xs" style="font-size: 8px">{{ student.email }}</div>
                                        </div>
                                    </div>
                                    <div class="custom-control custom-checkbox student-checkbox">
                                        <input type="checkbox" class="custom-control-input" [id]="'student_' + i" [(ngModel)]="student.checked" (change)="changeToggleCheckedUser()" />
                                        <label class="custom-control-label" [for]="'student_' + i"></label>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
        <div class="card-footer p-1">
            <div class="text-right">
                <button class="btn btn-secondary btn-min-width" (click)="dismiss()" rippleEffect>
                    <span>{{ 'Hủy' }}</span>
                </button>
                <button type="submit" class="btn btn-primary btn-min-width ml-1" (click)="assignmentUser()" rippleEffect>
                    <core-loading *ngIf="loadingStates?.['assignmentStudent']?.loading" size="12"></core-loading>
                    <span class="ml-50">{{ 'Cập nhật' }}</span>
                </button>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #assignGroup>
    <div class="card shadow-none student-list m-0">
        <div class="card-body p-1">
            <div class="d-flex align-items-center">
                <div class="d-flex align-items-center">
                    <div class="custom-control custom-checkbox cursor-pointer">
                        <input type="checkbox" class="custom-control-input" id="checkAllGroupAssign" [(ngModel)]="checkAllGroupList" (change)="checkAllGroup()" />
                        <label class="custom-control-label" for="checkAllGroupAssign">
                            <span class="d-none d-md-inline-block">{{ 'Chọn tất cả' }}</span>
                        </label>
                    </div>
                </div>
                <div class="ml-auto d-flex align-items-center">
                    <div class="input-group input-group-merge w-100">
                        <div class="input-group-prepend cursor-pointer" (click)="getGroupList('searchGroup')">
                            <span class="input-group-text" style="height: 33px">
                                <lucide-icon name="search" size="16" *ngIf="!loadingStates?.['searchGroup']?.loading"></lucide-icon>
                                <core-loading size="16" *ngIf="loadingStates?.['searchGroup']?.loading"></core-loading>
                            </span>
                        </div>
                        <input
                            type="text"
                            id="chat-search"
                            style="height: 33px"
                            [(ngModel)]="optionsGroup.search"
                            placeholder="Tìm kiếm .."
                            (keyup.enter)="getGroupList('searchGroup')"
                            (ngModelChange)="optionsGroup.searchDataUpdate.next($event)"
                            class="form-control"
                        />
                    </div>
                </div>
            </div>
            <div class="mt-2">
                <div class="font-weight-bold m-0">
                    {{ 'Đã chọn: ' + totalCheckedGroup + ' nhóm' }}
                </div>
            </div>
        </div>
        <div class="card-footer custom-scroll white-scroll p-1">
            <core-loading name="loading-css" size="50" *ngIf="loadingStates['getGroupList'].loading; else doneLoadingGroup"></core-loading>
            <ng-template #doneLoadingGroup>
                <div class="row m-0">
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3 p-0" *ngFor="let group of groupList; let i = index">
                        <div class="card m-50">
                            <div class="card-body p-0">
                                <label [for]="'group_' + i" class="group-item">
                                    <div class="d-flex align-items-center">
                                        <div class="avatar bg-light-primary">
                                            <div class="avatar-content">
                                                <lucide-icon name="users" class="avatar-icon"></lucide-icon>
                                            </div>
                                        </div>
                                        <div class="ml-50">
                                            <div>{{ group.name }}</div>
                                        </div>
                                    </div>
                                    <div class="custom-control custom-checkbox group-checkbox">
                                        <input type="checkbox" class="custom-control-input" [id]="'group_' + i" [(ngModel)]="group.checked" (change)="changeToggleCheckedGroup()" />
                                        <label class="custom-control-label" [for]="'group_' + i"></label>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
        <div class="card-footer p-1">
            <div class="text-right">
                <button class="btn btn-secondary btn-min-width" (click)="dismiss()" rippleEffect>
                    <span>{{ 'Hủy' }}</span>
                </button>
                <button type="submit" class="btn btn-primary btn-min-width ml-1" (click)="assignmentUser()" rippleEffect>
                    <core-loading *ngIf="loadingStates?.['assignmentStudent']?.loading" size="12"></core-loading>
                    <span class="ml-50">{{ 'Cập nhật' }}</span>
                </button>
            </div>
        </div>
    </div>
</ng-template>

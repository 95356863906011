// draggable.directive.ts
import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { DragDropService } from './drag-drop.service';

@Directive({
    selector: '[appDraggable]',
})
export class DraggableDirective {
    @Input() appDraggable: any;
    private offsetX = 0;
    private offsetY = 0;

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        private dragDropService: DragDropService
    ) { }

    @HostListener('mousedown', ['$event'])
    onDragStart(event: any): void {
        event.preventDefault();
        this.dragDropService.setDraggedItem(this.appDraggable);
        const padding = 20;
        const rect = this.el.nativeElement.getBoundingClientRect();
        this.offsetX = event.clientX - rect.left;
        this.offsetY = event.clientY - rect.top;

        // Create a clone of the item for dragging

        const clone = this.el.nativeElement.cloneNode(true);
        this.renderer.setStyle(clone, 'position', 'absolute');
        this.renderer.setStyle(clone, 'z-index', '10');
        this.renderer.setStyle(clone, 'left', event.clientX - this.offsetX + 'px');
        this.renderer.setStyle(clone, 'top', event.clientY - this.offsetY - event.target?.clientHeight - padding  + 'px');
        this.renderer.setStyle(clone, 'width', event.target?.clientWidth + 'px');
        // this.renderer.setStyle(clone, 'height', event.target?.clientHeight + 'px');
        this.renderer.setStyle(clone, 'border', '1px solid #bebec8');
        this.renderer.addClass(clone, 'dragged-placeholder');
        document.body.appendChild(clone);

        const moveListener = this.renderer.listen('document', 'mousemove', (moveEvent: MouseEvent) => {
            this.renderer.setStyle(clone, 'left', moveEvent.clientX - this.offsetX + 'px');
            this.renderer.setStyle(clone, 'top', moveEvent.clientY - this.offsetY - event.target?.clientHeight + 'px');
        });

        const upListener = this.renderer.listen('document', 'mouseup', () => {
            moveListener();
            upListener();
            this.renderer.removeChild(document.body, clone);
            this.dragDropService.clearDraggedItem();
        });
    }
}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CoreLoadingModule} from '@core/components/core-loading/core-loading.module';
import {NgbDateParserFormatter, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {EditorModule, TINYMCE_SCRIPT_SRC} from '@tinymce/tinymce-angular';
import {NgbDateCustomParserFormatter} from 'app/core/datepicker/datepicker';
import {DirectiveModule} from 'app/core/directives/directive.module';
import {CdnPipeModule} from 'app/core/pipes/cdn/cdn.pipe.module';
import {CurrencyPipeModule} from 'app/core/pipes/currency/currency.pipe.module';
import {environment} from 'environments/environment';
import {LucideAngularModule, icons} from 'lucide-angular';
import {AssignmentDialogComponent} from './assignment-dialog/assignment-dialog.component';
import {AvatarComponent} from './avatar/avatar.component';
import {CourseItemComponent} from './course-item/course-item.component';
import {DateTimePickerComponent} from './date-time-picker/date-time-picker.component';
import {DialogConfirmComponent} from './dialogs/dialog-confirm/dialog-confirm.component';
import {DialogCropperComponent} from './dialogs/dialog-cropper/dialog-cropper.component';
import {DrawerComponent} from './drawer/drawer.component';
import {EditorComponent} from './editor/editor.component';
import {ExamItemComponent} from './exam-item/exam-item.component';
import {ImageCropperModule} from './image-cropper/image-cropper.module';
import {LoadingSkeletonComponent} from './loading-skeleton/loading-skeleton.component';
import {UploadImageComponent} from './upload-image/upload-image.component';
import {ViewToggleComponent} from './view-toggle/view-toggle.component';
@NgModule({
    declarations: [
        DialogConfirmComponent,
        AvatarComponent,
        LoadingSkeletonComponent,
        CourseItemComponent,
        UploadImageComponent,
        AssignmentDialogComponent,
        ViewToggleComponent,
        ExamItemComponent,
        DialogCropperComponent,
        DateTimePickerComponent,
        EditorComponent,
        DrawerComponent,
    ],
    imports: [
        NgbModule,
        FormsModule,
        CommonModule,
        CdnPipeModule,
        CoreLoadingModule,
        LucideAngularModule.pick(icons),
        CurrencyPipeModule,
        ImageCropperModule,
        DirectiveModule,
        EditorModule,
        ReactiveFormsModule,
    ],
    exports: [
        DialogConfirmComponent,
        AvatarComponent,
        LoadingSkeletonComponent,
        CourseItemComponent,
        UploadImageComponent,
        AssignmentDialogComponent,
        ViewToggleComponent,
        ExamItemComponent,
        ImageCropperModule,
        DateTimePickerComponent,
        EditorComponent,
        DrawerComponent,
    ],
    providers: [
        {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter},
        {provide: TINYMCE_SCRIPT_SRC, useValue: environment.tinyMceScriptSrc},
    ],
})
export class ComponentsUiModule {}

import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[appautowidth]'
})
export class AutoWidthDirective {

    constructor(private el: ElementRef) { }

    @HostListener('keyup') onKeyUp() {
        this.resize();
    }

    @HostListener('focus') onFocus() {
        this.resize();
    }

    private resize() {
        const padding = 2;
        const minWidth = 20;
        const maxWidth = 30;
        let width = this.el.nativeElement.value.length + padding;
        if(width > maxWidth) width = maxWidth;
        if(width < minWidth) width = minWidth;
        this.el.nativeElement.setAttribute('size', width);
    }
}